import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { useAuth } from '../../contexts/AuthContext';
import {Link} from 'react-router-dom';
import { DeleteOutline } from '@material-ui/icons';
import companyIcon from '../../assets/images/business-24px.svg'
import { removeCompany } from '../../utils/funcs';

const CompaniesList = () => {
    const {companies, updateState} = useAuth();
    const [loaded, setLoaded] = useState(false);
    const [modal, setModal] = useState({
        open: false,
        title: 'Firma entfernen',
        id: ''
    });

    useEffect(() => {
        setLoaded(true);
        document.title = 'BSC CC - Grundprofil';
    }, []);

    const sortByDate = (array) => {
        array.sort((a,b) => {
            const dateA = a.createdAt.toDate();
            const dateB = b.createdAt.toDate();
            return dateB - dateA;
        });

        return array;
    }

    const handleDelete = (companyId) => {
        if (!modal.open) {
            setModal({...modal, open: true, id: companyId});
        } else {
            removeCompany(companyId).then(() => {
                updateState();
                setModal({
                    open: false,
                    title: 'Firma entfernen',
                    id: ''
                });
            });
        }
    }

    return loaded && <>
        <ul id="companies-list">
            {_.map(sortByDate(companies), (data, index) => {
                return<li key={index}>
                    <Link to={{
                        pathname: '/firmen/'+data.name.toLowerCase().split(' ').join('-')+'/'+data.id+'/angestellte'
                    }}>
                        <img src={companyIcon} alt={data.name}/>
                        <div className="details-title">
                            <h3>{data.name}</h3>
                            <h4>{data.employees.length} Mitarbeiter</h4>
                        </div>
                    </Link>
                    <DeleteOutline className="btn-remove" onClick={() => handleDelete(data.id)} />
                </li>
            })}
        </ul>
        {modal.open && <div className="modal">
            <h2>{modal.title}</h2>
            <p>Sind Sie sicher, dass Sie diese Firma löschen wollen?</p>
            <div className="btn-group">
                <button type="button" className="btn-standard" onClick={() => handleDelete(modal.id)}>Ja</button>
                <button type="button" className="btn-standard" onClick={() => {
                    setModal({
                        open: false,
                        title: 'Firma entfernen',
                        id: ''
                    });
                }}>Nein</button>
            </div>
        </div>}
    </>
}

export default CompaniesList;