import React, { useContext, useEffect, useState } from 'react';
import { auth, firestore } from '../utils/firebase';
import firebase from 'firebase';
import { getEvents } from '../utils/events';

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [companies, setCompanies] = useState([]);
  const [events, setEvents] = useState(null);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [linkOpen] = useState(null);

  function signIn(email, password) {
    return auth
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password);
      });
  }

  function signOut() {
    return auth.signOut().then(() => (window.location = '/'));
  }

  async function updateEvents() {
    return await getEvents()
      .then((e) => {
        setEvents(e);
        return e;
      })
      .then((ev) => ev);
  }

  function updateCompanies() {
    const companiess = [];
    firestore
      .collection('companies')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          const employees = [];
          doc.ref
            .collection('employees')
            .get()
            .then((empData) => {
              empData.forEach((emp) => {
                employees.push(emp.data());
              });
            })
            .then(() => {
              companiess.push({
                id: doc.id,
                name: doc.data().name,
                employees: employees,
                createdAt: doc.data().createdAt
              });
              setCompanies([...companiess]);
            });
        });
      });
  }

  function updateQuestionnairess() {
    const questionnairess = [];
    firestore
      .collection('questionnaires')
      .get()
      .then((data) => {
        data.forEach((doc) => {
          questionnairess.push({
            id: doc.id,
            data: doc.data(),
            createdAt: doc.data().createdAt.toDate()
          });
        });
      })
      .then(() => {
        setQuestionnaires([...questionnairess]);
      });
  }

  function updateState() {
    if (currentUser) {
      updateCompanies();
      updateQuestionnairess();

      if (!events) {
        getEvents().then((e) => setEvents(e));
      }
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      updateState();
    });
    return unsubscribe;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const value = {
    currentUser,
    companies,
    updateCompanies,
    questionnaires,
    updateState,
    linkOpen,
    signIn,
    signOut,
    updateEvents,
    events
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
