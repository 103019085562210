import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import {
  addEmployee,
  blockLink,
  checkIfOpenLinkExist,
  getCompany,
  getEmployees,
  removeEmployee,
  toggleOpenLinkGround
} from '../../utils/funcs';
import classnames from 'classnames';
import { ArrowBackIos, DeleteOutline } from '@material-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory, useParams } from 'react-router-dom';
import empIcon from '../../assets/images/perm_identity-24px.svg';
import linkIcon from '../../assets/images/link-24px.svg';
import blockIcon from '../../assets/images/block-blue.svg';
import moment from 'moment';

const EmployeeList = () => {
  const { id } = useParams();
  const [employees, setEmployees] = useState(null);
  const [openAdd] = useState(false);
  const [modal, setModal] = useState({
    open: false,
    title: 'Mitarbeiter entfernen',
    id: ''
  });
  const [company, setCompany] = useState(null);
  const { updateState, currentUser } = useAuth();
  const history = useHistory();

  useEffect(() => {
    getEmployees(id).then((res) => setEmployees(res));
    getCompany(id).then((res) => setCompany(res));
    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    document.title = 'BSC CC - Grundprofil';
  }, []);
  useEffect(() => {
    if (company && company.linkOpen === undefined) {
      checkIfOpenLinkExist(id).then(() => updateState());
    }
    // eslint-disable-next-line
  }, [company]);

  const handleSubmit = (e, id, company) => {
    e.preventDefault();
    const { name, surname, email } = e.target.elements;

    if (name.value && surname.value && email.value) {
      const data = {
        name: name.value,
        surname: surname.value,
        email: email.value,
        invited: new Date(),
        answered: {
          answer: false,
          date: ''
        }
      };
      addEmployee(id, data, company).then(() => {
        document.getElementById('add-employee-form').reset();
        getEmployees(id).then((res) => {
          setEmployees(res);
        });
      });
    }
  };

  const handleRemove = (employeeId) => {
    if (!modal.open) {
      setModal({ ...modal, open: true, id: employeeId });
    } else {
      removeEmployee(id, employeeId).then(() => {
        getEmployees(id).then((res) => {
          setEmployees(res);
          setModal({
            open: false,
            title: 'Mitarbeiter entfernen',
            id: ''
          });
        });
      });
    }
  };

  const handleToggleLink = (e) => {
    e.preventDefault();
    toggleOpenLinkGround(id, company.linkOpen).then(() =>
      getCompany(id).then((res) => setCompany(res))
    );
  };

  return (
    company &&
    employees && (
      <div id='employees'>
        {currentUser && company && (
          <Link
            to={'/grundprofil'}
            className='back-btn'
            style={{ marginTop: 20 }}
          >
            <ArrowBackIos /> Zurück
          </Link>
        )}
        <h1>{company.name}</h1>
        <div className='options'>
          {/* <button className="btn-standard" type="button" onClick={(e) => {
                setOpenAdd(!openAdd);
            }}>
                <Add/> MA Hinzufügen
            </button> */}
          <div className='open'>
            <h3>Link öffentlich:</h3>
            <div
              className={classnames('toggle-btn', {
                toggled: company.linkOpen
              })}
              onClick={handleToggleLink}
            >
              <button />
            </div>
          </div>
          <div className='url-link'>
            <textarea
              className={classnames({ active: company.linkOpen })}
              defaultValue={
                window.location.origin +
                '/firmen/' +
                company.name.toLowerCase().split(' ').join('-') +
                '/' +
                id +
                '/fragebogen/'
              }
              onClick={(e) => {
                e.target.select();
                e.target.setSelectionRange(0, 99999);
                document.execCommand('copy');
                alert('Link wurde kopiert!');
              }}
            />
          </div>
        </div>
        <div className={classnames('form', { open: openAdd })}>
          <form
            onSubmit={(e) => handleSubmit(e, id, company)}
            id='add-employee-form'
          >
            <label>
              <input type='text' name='name' placeholder='Vorname' />
            </label>
            <label>
              <input type='text' name='surname' placeholder='Nachname' />
            </label>
            <label>
              <input type='email' name='email' placeholder='Email' />
            </label>
            <button className='btn-accept' type='submit'>
              Hinzufügen
            </button>
          </form>
        </div>
        <ul className='employees-list'>
          {_.map(
            _.sortBy(employees, (e) => -e.invited),
            (empData, idx) => {
              return (
                <li
                  key={idx}
                  className={classnames('employee', {
                    answered: empData.answered ? empData.answered.answer : false
                  })}
                >
                  <img src={empIcon} alt='Employee icon' />
                  <div className='user-name'>
                    <span className='name'>{empData.name}</span>
                    <span className='surname'>{empData.surname}</span>
                    <span className='email'>{empData.email}</span>
                    {empData.invited && (
                      <span className='email'>
                        Eingeladen am{' '}
                        {moment(empData.invited).format('DD.MM.YYYY HH:mm')}
                      </span>
                    )}
                  </div>
                  <p
                    className={classnames('answer', {
                      answered: empData.answered
                        ? empData.answered.answer
                        : false
                    })}
                  >
                    {empData.answered && empData.answered.answer
                      ? 'Beantwortet'
                      : 'Keine Ergebnisse'}
                  </p>
                  <div
                    className='block'
                    onClick={(e) => {
                      blockLink(id, empData.id).then(() => {
                        // updateState();
                        getEmployees(id).then((res) => {
                          setEmployees(res);
                        });
                      });
                    }}
                  >
                    <img src={blockIcon} alt='Block icon' />
                  </div>
                  <div
                    className={classnames('link', {
                      blocked:
                        empData.deactivatedLink &&
                        empData.deactivatedLink.deactivated
                    })}
                    data-link={
                      empData.deactivatedLink &&
                      empData.deactivatedLink.deactivated
                        ? 'Link ist blockiert'
                        : empData.url
                    }
                    onClick={(e) => {
                      navigator.clipboard.writeText(empData.url).then((res) => {
                        e.target.classList.add('copied');
                        setTimeout(() => {
                          e.target.classList.remove('copied');
                        }, 1000);
                      });
                    }}
                  >
                    <img src={linkIcon} alt='Link icon' />
                  </div>
                  {empData.answered && empData.answered.answer && (
                    <button
                      className='btn-standard'
                      type='button'
                      onClick={() =>
                        history.push(
                          empData.url.split(`${window.location.origin}`)[1] +
                            '/profile-statistics'
                        )
                      }
                    >
                      Ergebnis anschauen
                    </button>
                  )}
                  <div
                    className='delete'
                    onClick={(e) => handleRemove(empData.id)}
                  >
                    <DeleteOutline className='' disabled />
                  </div>
                </li>
              );
            }
          )}
        </ul>
        {modal.open && (
          <div className='modal'>
            <h2>{modal.title}</h2>
            <p>Sind Sie sicher, dass Sie diesen Mitarbeiter löschen wollen?</p>
            <div className='btn-group'>
              <button
                type='button'
                className='btn-standard'
                onClick={() => handleRemove(modal.id)}
              >
                Ja
              </button>
              <button
                type='button'
                className='btn-standard'
                onClick={() => {
                  setModal({
                    open: false,
                    title: 'Mitarbeiter entfernen',
                    id: ''
                  });
                }}
              >
                Nein
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
};

export default EmployeeList;
