import { Close, Delete, Event } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { updateEvent } from '../utils/events';
import * as _ from 'lodash';
import { v4 } from 'uuid';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import logo from '../assets/images/logo_passion-wine_black.png';
import '../components/virtualWineTasting/virtualWineTasting.scss';

const ModalEdit = ({ eventData, onSubmited }) => {
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [textField, setTextField] = useState([]);
  const [logoPreview, setLogoPreview] = useState(null);
  const [bgPreview, setBgPreview] = useState(null);

  useEffect(() => {
    if (eventData) {
      setLogoPreview(eventData.logo);
      setDateStart(eventData.dateStart);
      setDateEnd(eventData.dateEnd);
      setTextField(eventData.textFields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditedSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      logo,
      description,
      template,
      slogan,
      slogan_text,
      greeting,
      greeting_text,
      event_bg,
      compliance
    } = e.target.elements;
    if (name.value) {
      updateEvent({
        'name': name.value || eventData.name,
        'asset_image':
          (event_bg && event_bg.files[0]) || eventData.asset_image || false,
        'asset_image_date': eventData.asset_image_date || false,
        'compliance': compliance.checked,
        'logo': (logo && logo.files[0]) || eventData.logo,
        'logo_date': eventData.logo_date || false,
        'description': description.value || eventData.description,
        'template': template.checked,
        'textFields': textField || false,
        'dateStart': dateStart,
        'dateEnd': dateEnd,
        'slogan': slogan.value || eventData.slogan,
        'slogan_text': slogan_text.value || eventData.slogan_text,
        'greeting': greeting.value || eventData.greeting,
        'greeting_text': greeting_text.value || eventData.greeting_text,
        'createdAt': eventData.createdAt,
        'id': eventData.id,
        'register-url': eventData['register-url']
      }).then(() => {
        document.getElementById('edit-event-form').reset();
        onSubmited(true);
      });
    }
  };
  return (
    eventData && (
      <div className='modal'>
        <h3>
          Event hinzufügen/bearbeiten{' '}
          <Close onClick={() => onSubmited(false)} />
        </h3>
        <form
          className='form'
          onSubmit={handleEditedSubmit}
          id='edit-event-form'
        >
          <label>
            <p>Name *</p>
            <input
              type='text'
              name='name'
              defaultValue={(eventData && eventData.name) || ''}
              required
            />
          </label>
          <label>
            <p>Event Hintergrundbild</p>
            {bgPreview && (
              <img
                src={bgPreview}
                alt={eventData.name + 'eventBg'}
                className='event-preview'
              />
            )}
            <input
              type='file'
              name='event_bg'
              accept='.jpg, .png, .jpeg, .webp'
              onChange={(e) => {
                setBgPreview(window.URL.createObjectURL(e.target.files[0]));
              }}
            />
          </label>
          {/* <label>
            <p>Logo</p>
            {logoPreview && <img src={logoPreview} alt={eventData.name + 'logo'} className="logo-preview"/>}
            <input type="file" name="logo" accept=".jpg, .png, .jpeg, .webp" onChange={(e) => {
                setLogoPreview(window.URL.createObjectURL(e.target.files[0]));
            }}/>
        </label> */}
          <label className='image-input'>
            <p>Logo</p>
            <div className='logo-preview'>
              <p>Your Company Logo goes here</p>
              <img
                src={
                  logoPreview
                    ? logoPreview
                    : eventData.logo
                    ? eventData.logo
                    : logo
                }
                alt='Logo'
              />
            </div>
            <input
              type='file'
              name='logo'
              accept='.jpg, .png, .jpeg, .webp'
              onChange={(e) => {
                setLogoPreview(window.URL.createObjectURL(e.target.files[0]));
              }}
            />
          </label>
          <label className='checkbox'>
            <p>Compliance checkbox</p>
            <input
              type='checkbox'
              name='compliance'
              defaultChecked={eventData.compliance}
            />
          </label>
          <label>
            <p>Datum und Uhrzeit des Events</p>
            <DateTimePicker
              onChange={(v) => setDateStart(v)}
              value={dateStart}
              calendarIcon={<Event className='calendar-icon' />}
            />
          </label>
          <label>
            <p>Deadline</p>
            <DateTimePicker
              onChange={(v) => setDateEnd(v)}
              value={dateEnd}
              calendarIcon={<Event className='calendar-icon' />}
            />
          </label>
          <label>
            <p>Notiz</p>
            <textarea
              type='text'
              name='description'
              defaultValue={(eventData && eventData.description) || ''}
            />
          </label>
          <p className='sub-title'>Intro</p>
          <label>
            <p>Slogan</p>
            <input
              type='text'
              name='slogan'
              defaultValue={(eventData && eventData.slogan) || ''}
            />
          </label>
          <label>
            <p>Intro Text</p>
            <textarea
              type='text'
              name='slogan_text'
              defaultValue={(eventData && eventData.slogan_text) || ''}
            />
          </label>
          <p className='sub-title'>Greeting Box</p>
          <label>
            <p>Greeting Slogan</p>
            <input
              type='text'
              name='greeting'
              defaultValue={(eventData && eventData.greeting) || ''}
            />
          </label>
          <label>
            <p>Greeting Text</p>
            <textarea
              type='text'
              name='greeting_text'
              defaultValue={(eventData && eventData.greeting_text) || ''}
            />
          </label>
          <p className='sub-title'>Freie Textfelder</p>
          <button
            className='back-btn'
            onClick={(e) => {
              e.preventDefault();
              setTextField((old) => {
                return [...old, { id: v4(), description: '' }];
              });
            }}
          >
            Textfeld hinzufügen
          </button>
          {!_.isEmpty(textField) && (
            <div>
              {_.map(
                textField,
                (el, key) =>
                  el && (
                    <label key={key + '-' + el.id}>
                      <p className='text-field-title'>
                        Textfield {key + 1}{' '}
                        <Delete
                          onClick={() =>
                            setTextField(textField.filter((e, i) => i !== key))
                          }
                        />
                      </p>
                      <textarea
                        type='text'
                        name={'Textfield' + el.id}
                        defaultValue={el.description}
                        onChange={(e) =>
                          setTextField((old) => {
                            old[key].description = e.target.value;
                            return [...old];
                          })
                        }
                      />
                    </label>
                  )
              )}
            </div>
          )}
          <label className='flex-box'>
            <p>Speichern als template</p>
            <input
              type='checkbox'
              name='template'
              defaultChecked={eventData.template}
            />
          </label>
          <button className='btn-accept'>Speichern</button>
        </form>
      </div>
    )
  );
};

export default ModalEdit;
