import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { getEmployee, sendAnswer } from '../utils/funcs';
import * as _ from 'lodash';
import { Block } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const Questions = () => {
  const { cpid, id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [employee, setEmployee] = useState(null);
  const [sortedStats, setSortedStats] = useState(null);
  const [stats, setStats] = useState({
    'ad-task': 0,
    'an-task': 0,
    'co-sit': 0,
    'combination': 0,
    'concept': 0,
    'contact': 0,
    'contacting': 0,
    'controll': 0,
    'creativity': 0,
    'deatils-warn': 0,
    'details': 0,
    'empathie': 0,
    'fact-work': 0,
    'feeling-tasks': 0,
    'finances': 0,
    'group-talk': 0,
    'idea': 0,
    'imagination': 0,
    'learning': 0,
    'logic': 0,
    'math': 0,
    'new-create': 0,
    'new-init': 0,
    'new-method': 0,
    'or-tasks': 0,
    'planing': 0,
    'ra-work': 0,
    'risico': 0,
    'solutions': 0,
    'tech-sit': 0,
    'work-details': 0,
    'write': 0
  });

  const handleAnswer = () => {
    if (!_.includes(stats, 0)) {
      sendAnswer(cpid, id, stats).then(() =>
        history.push(history.location.pathname + '/profile-statistics')
      );
    } else {
      alert('Bitte beantworten Sie alle Fragen.');
    }
  };

  const getLabel = (key) => {
    switch (key) {
      case 'ad-task':
        return 'Administrative Aufgaben';
      case 'an-task':
        return 'Analytische Aufgabe';
      case 'co-sit':
        return 'Koordinieren unterschiedlicher Aspekte';
      case 'combination':
        return 'Kombinieren neuartiger Aspekte';
      case 'concept':
        return 'Konzepterstellung';
      case 'contact':
        return 'Kontakte zu anderen Personen';
      case 'contacting':
        return 'Kommunizieren, Gespräche führen';
      case 'controll':
        return 'Kontrollieren, überwachen';
      case 'creativity':
        return 'Kreativität, neue Ideen';
      case 'deatils-warn':
        return 'Details genau beachten';
      case 'details':
        return 'Details zu einem Ganzen zusammenfügen';
      case 'empathie':
        return 'Empathie, Einfühlungsvermögen in andere Menschen';
      case 'fact-work':
        return 'Arbeiten mit Fakten und Sachinformationen';
      case 'feeling-tasks':
        return 'Gefühlsbetonte Aufgaben';
      case 'finances':
        return 'Betriebswirtschaft, Finanzen';
      case 'group-talk':
        return 'Gespräche mit Gruppen';
      case 'idea':
        return 'Ideen ausdrücken';
      case 'imagination':
        return 'Denken in Bildern';
      case 'learning':
        return 'Unterweisen und trainieren';
      case 'logic':
        return 'Logisch denken';
      case 'math':
        return 'Rechnen und Mathematik';
      case 'new-create':
        return 'Neues entwickeln';
      case 'new-init':
        return 'Dinge initiieren, Neues aufgreifen';
      case 'new-method':
        return 'Neue Methoden entwickeln';
      case 'or-tasks':
        return 'Organisieren von Aufgaben';
      case 'planing':
        return 'Planen';
      case 'ra-work':
        return 'Rationales Arbeiten';
      case 'risico':
        return 'Risiken erkennen, abwägen';
      case 'solutions':
        return 'Problemlösungen erarbeiten';
      case 'tech-sit':
        return 'Technische Aspekte';
      case 'work-details':
        return 'Arbeiten detailliert ausführen';
      case 'write':
        return 'Schriftliche Mitteilungen erstellen';
      default:
        return false;
    }
  };

  useEffect(() => {
    const sorted = {};
    document.title = 'BSC CC - Grundprofil';
    getEmployee(cpid, id).then((data) => setEmployee(data));
    Object.keys(stats)
      .sort()
      .forEach(function (key) {
        sorted[key] = stats[key];
      });
    if (!_.isEmpty(sorted)) {
      setSortedStats(sorted);
    }
    // eslint-disable-next-line
  }, []);

  return employee &&
    employee.deactivatedLink &&
    employee.deactivatedLink.deactivated ? (
    <div id='blocked'>
      <h1>
        <Block /> {t('Error')}
      </h1>
      <p>{t('The validity of your link has expired.')}</p>
    </div>
  ) : (
    <div id='questions'>
      {employee && (
        <>
          <span className='pre-title'>
            {t('PORTAL FOR TRAINING PARTICIPANTS')}
          </span>
          <h1>{t('Creativity and competence - My "basic profile"')}</h1>
          <p>
            <b>
              {t('Hi')} {employee.name} {employee.surname}
            </b>
            !<br />
            {t(
              'Werten Sie nach der folgenden Skala Ihre Person. Seien Sie so objektiv wie möglich. Es kommt darauf an, wie gern oder weniger gern Sie persönlich diese Aspekte in Ihrem Leben anwenden. Versuchen Sie, alle Werte der Skala zu verwenden, damit ein möglichst differenziertes Bild entsteht. Überzeichnen Sie eher als zu „lauwarmen“ Mittelwerten zu greifen.',
              { fallbackLng: 'de' }
            )}
          </p>
        </>
      )}
      {employee && (
        <div className='selects'>
          {_.map(sortedStats, (value, key) => {
            const numbers = [1, 2, 3, 4, 5, 6];
            return (
              <div key={key} className='select' id={key}>
                <p className='label'>
                  {t(getLabel(key), { fallbackLng: 'de' })}
                </p>
                <div className='an'>
                  <div className='sub-details'>
                    <span>{t('Weniger gern', { fallbackLng: 'de' })}</span>
                    <span>{t('Mittel', { fallbackLng: 'de' })}</span>
                    <span>{t('Sehr gern', { fallbackLng: 'de' })}</span>
                  </div>
                  <div className='to-select'>
                    {_.map(numbers, (n, i) => {
                      return (
                        <span
                          className='number'
                          key={i}
                          data-point={n}
                          onClick={(e) => {
                            document
                              .querySelectorAll(`#${key} .number`)
                              .forEach((el) => el.classList.remove('active'));
                            e.target.classList.add('active');
                            setStats({ ...stats, [key]: n });
                          }}
                        >
                          {n}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
          {employee && (
            <button
              className='btn-standard'
              type='button'
              onClick={handleAnswer}
            >
              {t('Zur Auswertung', { fallbackLng: 'de' })}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default Questions;
