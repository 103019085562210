import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAuth } from '../../contexts/AuthContext';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { Edit, ArrowBackIos } from '@mui/icons-material';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import './virtualWineTasting.scss';
import ModalEdit from '../../widgets/modal';
import classNames from 'classnames';
import { changePublicState } from '../../utils/events';

const EventComponent = () => {
  const { name, id } = useParams();
  const { events, currentUser, updateEvents } = useAuth();
  const [event, setEvent] = useState(null);
  const [registered, setRegistered] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(events) && !event) {
      setEvent(events.filter((e) => e.id === id)[0]);
      updateEvents();
    } else if (
      event &&
      !registered &&
      event.registered &&
      event.registered.length > 0
    ) {
      setRegistered(event.registered);
    }
    // eslint-disable-next-line
  }, [events, event]);

  useEffect(() => {
    if (registered) {
      setCsvData((old) => {
        const data = [];
        _.orderBy(registered, 'createdAt', 'desc').forEach((el) => {
          data.push([
            el.company_name ? el.company_name : '',
            el.name + ' ' + el.surname,
            el.country.code,
            el.street + ' ' + el.house_number,
            '',
            '',
            el.city,
            '',
            el.zip_code,
            el.country.code !== 'DE' ? el.phone : '',
            el.alcohol_free ? 'Ja' : 'Nein',
            // el.date_of_birth
            //   ? moment(el.date_of_birth).format('MM/DD/YYYY')
            //   : '',
          ]);
        });
        return data;
      });
    }
  }, [registered]);

  const handleChangePublicStatus = () => {
    changePublicState(!event.public, event.id).then(() => {
      updateEvents().then((res) =>
        setEvent(res.filter((e) => e.id === id)[0])
      );
    });
  };

  return event ? (
    <div id="event">
      {currentUser && (
        <Link
          to={'/virtual-wine-tasting'}
          className="back-btn"
          style={{ marginTop: 20 }}
        >
          <ArrowBackIos /> Zurück
        </Link>
      )}
      <div className="event-header">
        {event.logo && (
          <img
            className="logo"
            src={event.logo}
            alt={event.name}
            width="70"
            height="70"
          />
        )}
        <h3>{event.name}</h3>
        <button
          className="btn-tertiary"
          onClick={() => setShowModal(true)}
        >
          <Edit /> Bearbeiten
        </button>
      </div>
      <div className="event-info">
        <ul className="info-list">
          <li>
            <p className="info-title">Link</p>
            <div className="url-box">
              <a href={event['register-url']}>
                {event['register-url']}
              </a>
              <div
                className={classNames('toggle-btn', {
                  toggled: event.public,
                })}
                onClick={handleChangePublicStatus}
              >
                <button />
              </div>
            </div>
          </li>
          <li>
            <p className="info-title">Event Datum</p>
            <p className="info-description">
              {moment(event.dateStart).format('DD.MM.YYYY, HH:mm')}
            </p>
          </li>
          <li>
            <p className="info-title">Deadline</p>
            <p className="info-description">
              {moment(event.dateEnd).format('DD.MM.YYYY')}
            </p>
          </li>
          {event.description && (
            <li>
              <p className="info-title">Notiz</p>
              <p className="info-description">{event.description}</p>
            </li>
          )}
        </ul>
      </div>
      <div className="export-csv">
        <CSVLink
          filename={
            name +
            '-registered-list_' +
            moment().format('DD_MM_YYYY') +
            '.csv'
          }
          data={csvData}
          className="btn-csv"
        >
          CSV Herunterladen
        </CSVLink>
      </div>
      {registered && registered.length > 0 && (
        <div className="event-registrations">
          <table className="registered-list">
            <thead>
              <tr>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>E-mail</th>
                {/* <th>Geburtsdatum</th> */}
                <th>Straße</th>
                <th>Haus Nr.</th>
                <th>PLZ</th>
                <th>Ort</th>
                <th>Land</th>
                <th>Telefon</th>
                <th>Heimadresse</th>
                <th>Firma</th>
                <th>{event.compliance ? 'Selbstkosten' : ''}</th>
                <th>Alkoholfrei</th>
                <th>Datenschutz</th>
              </tr>
            </thead>
            <tbody>
              {_.map(
                _.orderBy(registered, 'createdAt', 'desc'),
                (r, key) => {
                  return (
                    <tr key={key}>
                      <td>{r.name}</td>
                      <td>{r.surname}</td>
                      <td>{r.email}</td>
                      {/* <td>
                        {r.date_of_birth &&
                          moment(r.date_of_birth).format(
                            'MM/DD/YYYY'
                          )}
                      </td> */}
                      <td>{r.street}</td>
                      <td>{r.house_number}</td>
                      <td>{r.zip_code}</td>
                      <td>{r.city}</td>
                      <td>{r.country.code}</td>
                      <td>{r.phone}</td>
                      <td>{r.home_address ? 'Ja' : 'Nein'}</td>
                      <td>
                        {!r.home_address ? r.company_name : 'Nein'}
                      </td>
                      <td>
                        {event.compliance
                          ? r.cover_costs
                            ? 'Ja'
                            : 'Nein'
                          : ''}
                      </td>
                      <td>{r.alcohol_free ? 'Ja' : 'Nein'}</td>
                      <td>{r.data_privacy ? 'Ja' : 'Nein'}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}
      {showModal && (
        <ModalEdit
          eventData={event}
          onSubmited={(val) => {
            setShowModal(false);
            if (val) {
              updateEvents().then((res) =>
                setEvent(res.filter((e) => e.id === id)[0])
              );
            }
          }}
        />
      )}
    </div>
  ) : (
    <h1>Loading...</h1>
  );
};
export default EventComponent;
