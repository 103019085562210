import './styles/styles.scss';
import Login from './components/auth/login.component';
import { AuthProvider } from './contexts/AuthContext';
import { Switch, Route, withRouter } from 'react-router-dom';
import { matchPath } from 'react-router';
import Dashboard from './components/dashboard.component';
import PrivateRoute from './components/auth/private.component';
import Questions from './components/questions.component';
import Statistics from './components/statistics.component';
import Header from './components/header.component';
import EmployeeList from './components/dashboard/employeeList.component';
import GroundProfile from './components/groundProfile/groundProfile.component';
import CompanyInvite from './components/groundProfile/companyInvite.component';
import Questionnaire from './components/questionnaire/questionnaire.component';
import Individiual from './components/questionnaire/individual.component';
import IndividualQuestions from './components/questionnaire/individualQuestions.component';
import SecondQuestions from './components/questionnaire/secondQuestions.component';
import Answer from './components/questionnaire/answer.component';
import VirtualWineTasting from './components/virtualWineTasting/virtualWineTasting.component';
import EventComponent from './components/virtualWineTasting/events.component';
import Registration from './components/registration/registration.component';
import { RegisteredProvider } from './contexts/RegisteredContext';
import RegistrationComplete from './components/registration/registration.complete.component';
const App = (props) => {
  const isRegistrationAvtive = matchPath(
    props.location.pathname,
    '/virtual-wine-tasting/:companyName/:uuid'
  );
  return (
    <div id='app'>
      <AuthProvider>
        {!isRegistrationAvtive && <Header />}
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/login' component={Login} />
          <Route
            exact
            path='/questionnaire/:company/:cpid/:id'
            component={Questions}
          />
          <Route
            exact
            path='/questionnaire/:company/:cpid/:id/profile-statistics'
            component={Statistics}
          />
          <Route
            exact
            path='/firmen/:companyName/:companyId/fragebogen'
            component={CompanyInvite}
          />
          <PrivateRoute
            exact
            path='/firmen/:company/:id/angestellte'
            component={EmployeeList}
          />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/grundprofil' component={GroundProfile} />
          <PrivateRoute
            exact
            path='/virtual-wine-tasting'
            component={VirtualWineTasting}
          />
          <PrivateRoute exact path='/fragebogen' component={Questionnaire} />
          <PrivateRoute
            exact
            path='/events/:name/:id'
            component={EventComponent}
          />
          <Route exact path='/individuell/fragebogen' component={Individiual} />
          <Route
            exact
            path='/individuell/fragebogen/:id'
            component={IndividualQuestions}
          />
          <Route
            exact
            path='/individuell/fragebogen/:id/fragen'
            component={SecondQuestions}
          />
          <Route
            exact
            path='/individuell/fragebogen/:id/auswertung'
            component={Answer}
          />
          <RegisteredProvider>
            <Route
              exact
              path='/virtual-wine-tasting/:companyName/:uuid'
              component={Registration}
            />
            <Route
              exact
              path='/virtual-wine-tasting/:companyName/:uuid/success'
              component={RegistrationComplete}
            />
          </RegisteredProvider>
        </Switch>
      </AuthProvider>
    </div>
  );
};

export default withRouter(App);
