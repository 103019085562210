import React, { useContext, useState } from 'react';

const RegisteredContext = React.createContext();

export const useRegistered = () => useContext(RegisteredContext);

export const RegisteredProvider = ({children}) => {
    const [registeredEvent, setRegisteredEvent] = useState(null);

    function updateRegisteredEvent(ev) {
        setRegisteredEvent(ev);
    }

    const value = {
        registeredEvent,
        updateRegisteredEvent
    }
    return <RegisteredContext.Provider value={value}>
        {children}
    </RegisteredContext.Provider>
}