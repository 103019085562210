import {
  Add,
  ArrowBackIos,
  Close,
  Delete,
  Edit,
  Event
} from '@material-ui/icons';
import { Download } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import * as _ from 'lodash';
import { addEvent, deleteEvent, getTemplates } from '../../utils/events';
import moment from 'moment';
import momenTZ from 'moment-timezone';
import { v4 } from 'uuid';
import './virtualWineTasting.scss';
import { CSVLink } from 'react-csv';
import ModalEdit from '../../widgets/modal';
import classNames from 'classnames';
import logo from '../../assets/images/logo_passion-wine_black.png';

const VirtualWineTasting = () => {
  const { currentUser, events, updateEvents } = useAuth();
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [dateStart, setDateStart] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(new Date());
  const [textField, setTextField] = useState([]);
  const [templates, setTemplates] = useState(null);
  const [selectedTemp, setSelectedTemp] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [modal, setModal] = useState({
    open: false,
    title: 'Event entfernen',
    data: null
  });

  useEffect(() => {
    getTemplates().then((res) => setTemplates(res));
    !loaded && setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateEvents();
    return events && false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalAdd, loadingMsg, modal]);

  const handleDelete = (event) => {
    if (!modal.open) {
      setModal((old) => ({ ...old, open: true, data: event }));
    } else {
      deleteEvent(event, event.logo, event.asset_iimage).then(() => {
        updateEvents();
        setModal({
          open: false,
          title: 'Event entfernen',
          data: null
        });
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      logo,
      description,
      template,
      slogan,
      slogan_text,
      greeting,
      greeting_text,
      event_bg,
      compliance
    } = e.target.elements;
    if (name.value) {
      setLoadingMsg(true);
      addEvent({
        name: name.value,
        logo: (logo && logo.files[0]) || false,
        asset_image: (event_bg && event_bg.files[0]) || false,
        compliance: compliance.checked,
        description: description.value || false,
        template: template.checked,
        textFields: textField || false,
        dateStart: dateStart,
        dateEnd: dateEnd,
        slogan: slogan.value || false,
        slogan_text: slogan_text.value || false,
        greeting: greeting.value || false,
        greeting_text: greeting_text.value || false,
        public: false
      }).then(() => {
        updateEvents();
        document.getElementById('add-event-form').reset();
        setShowModalAdd(!showModalAdd);
        setLoadingMsg(false);
      });
    } else {
      setLoadingMsg(false);
    }
  };

  return (
    <div id='vwt'>
      {currentUser && (
        <Link to={'/dashboard'} className='back-btn' style={{ marginTop: 20 }}>
          <ArrowBackIos /> Zurück
        </Link>
      )}
      <h2>Events</h2>
      <button
        className='btn-standard'
        type='button'
        onClick={(e) => {
          e.preventDefault();
          setShowModalAdd(!showModalAdd);
        }}
      >
        <Add /> Hinzufügen
      </button>
      {/* EVENTS */}
      {loaded && (
        <ul className='default-list'>
          {_.map(_.orderBy(events, 'createdAt', 'desc'), (event, key) => {
            const csvData = [];
            if (event.registered.length > 0) {
              event.registered.forEach((registered) => {
                csvData.push([
                  registered.company_name ? registered.company_name : '',
                  registered.name + ' ' + registered.surname,
                  registered.country.code,
                  registered.street + ' ' + registered.house_number,
                  '',
                  '',
                  registered.city,
                  '',
                  registered.zip_code,
                  registered.country.code !== 'DE' ? registered.phone : ''
                ]);
              });
            }
            return (
              <li key={key}>
                <Link to={'events/' + event.name + '/' + event.id}>
                  <div className='info-box'>
                    <p>{event.name}</p>
                    <span>
                      Event am{' '}
                      {moment(
                        momenTZ(event.dateStart).tz('Europe/Berlin')
                      ).format('DD-MM-YYYY, HH:mm')}
                    </span>
                  </div>
                </Link>
                <div className='btn-group'>
                  {csvData.length > 0 && (
                    <CSVLink
                      data={csvData}
                      filename={
                        event.name +
                        '-registered-list_' +
                        moment().format('DD_MM_YYYY') +
                        '.csv'
                      }
                    >
                      <Download />
                    </CSVLink>
                  )}
                  <Edit
                    onClick={() => {
                      setShowModalEdit(true);
                      setTextField(event.textFields);
                      setEventData(event);
                      setDateEnd(event.dateEnd);
                      setDateStart(event.dateStart);
                    }}
                  />
                  <Delete onClick={() => handleDelete(event)} />
                </div>
              </li>
            );
          })}
        </ul>
      )}
      {showModalAdd && (
        <div className='modal'>
          <h3>
            Event hinzufügen/bearbeiten{' '}
            <Close onClick={() => setShowModalAdd(!showModalAdd)} />
          </h3>
          <form className='form' onSubmit={handleSubmit} id='add-event-form'>
            {templates && (
              <div className='flex-box'>
                <p>Use Template</p>
                <div className='btn-group'>
                  {_.map(templates, (tp, key) => (
                    <button
                      key={key}
                      className='btn-secondary'
                      onClick={(e) => {
                        e.preventDefault();
                        setSelectedTemp(tp);
                        setDateStart(tp.dateStart);
                        setDateEnd(tp.dateEnd);
                        if (tp.textFields) {
                          setTextField(tp.textFields);
                        }
                      }}
                    >
                      {tp.name}
                    </button>
                  ))}
                </div>
              </div>
            )}
            <label>
              <p>Name *</p>
              <input
                type='text'
                name='name'
                defaultValue={(selectedTemp && selectedTemp.name) || ''}
                required
              />
            </label>
            <label>
              <p>Event Hintergrundbild</p>
              <input
                type='file'
                name='event_bg'
                accept='.jpg, .png, .jpeg, .webp'
              />
            </label>
            <label className='image-input'>
              <p>Logo</p>
              <div className='logo-preview'>
                <p>Your Company Logo goes here</p>
                <img src={logoPreview ? logoPreview : logo} alt='Logo' />
              </div>
              <input
                type='file'
                name='logo'
                accept='.jpg, .png, .jpeg, .webp'
                onChange={(e) => {
                  setLogoPreview(window.URL.createObjectURL(e.target.files[0]));
                }}
              />
            </label>
            <label>
              <p>Datum und Uhrzeit des Events</p>
              <DateTimePicker
                onChange={(v) => setDateStart(v)}
                value={dateStart}
                calendarIcon={<Event className='calendar-icon' />}
              />
            </label>
            <label>
              <p>Deadline</p>
              <DateTimePicker
                onChange={(v) => setDateEnd(v)}
                value={dateEnd}
                calendarIcon={<Event className='calendar-icon' />}
              />
            </label>
            <label>
              <p>Notiz</p>
              <textarea
                type='text'
                name='description'
                defaultValue={(selectedTemp && selectedTemp.description) || ''}
              />
            </label>
            <label className='checkbox'>
              <p>Compliance checkbox</p>
              <input
                type='checkbox'
                name='compliance'
                defaultChecked={selectedTemp && selectedTemp.compliance}
              />
            </label>
            <p className='sub-title'>Intro</p>
            <label>
              <p>Slogan</p>
              <input
                type='text'
                name='slogan'
                defaultValue={(selectedTemp && selectedTemp.slogan) || ''}
              />
            </label>
            <label>
              <p>Intro Text</p>
              <textarea
                type='text'
                name='slogan_text'
                defaultValue={(selectedTemp && selectedTemp.slogan_text) || ''}
              />
            </label>
            <p className='sub-title'>Greeting Box</p>
            <label>
              <p>Greeting Slogan</p>
              <input
                type='text'
                name='greeting'
                defaultValue={(selectedTemp && selectedTemp.greeting) || ''}
              />
            </label>
            <label>
              <p>Greeting Text</p>
              <textarea
                type='text'
                name='greeting_text'
                defaultValue={
                  (selectedTemp && selectedTemp.greeting_text) || ''
                }
              />
            </label>
            <p className='sub-title'>Freie Textfelder</p>
            <button
              className='back-btn'
              onClick={(e) => {
                e.preventDefault();
                setTextField((old) => {
                  return [...old, { id: v4(), description: '' }];
                });
              }}
            >
              Textfeld hinzufügen
            </button>
            {!_.isEmpty(textField) && (
              <div>
                {_.map(
                  textField,
                  (el, key) =>
                    el && (
                      <label key={key + '-' + el.id}>
                        <p className='text-field-title'>
                          Textfield {key + 1}{' '}
                          <Delete
                            onClick={() =>
                              setTextField(
                                textField.filter((e, i) => i !== key)
                              )
                            }
                          />
                        </p>
                        <textarea
                          type='text'
                          name={'Textfield' + el.id}
                          defaultValue={el.description}
                          onChange={(e) =>
                            setTextField((old) => {
                              old[key].description = e.target.value;
                              return [...old];
                            })
                          }
                        />
                      </label>
                    )
                )}
              </div>
            )}
            <label className='flex-box'>
              <p>Speichern als template</p>
              <input type='checkbox' name='template' />
            </label>
            <button
              className={classNames('btn-accept', {
                disabled: loadingMsg
              })}
              disabled={loadingMsg}
            >
              {loadingMsg ? 'Laden...' : 'Speichern'}
            </button>
          </form>
        </div>
      )}
      {showModalEdit && (
        <ModalEdit
          onSubmited={(val) => {
            setShowModalEdit(false);
            if (val) {
              updateEvents();
            }
          }}
          eventData={eventData}
        />
      )}
      {modal.open && (
        <div className='modal'>
          <h2>{modal.title}</h2>
          <p>Sind Sie sicher, dass Sie dieses Event löschen wollen?</p>
          <div className='btn-group'>
            <button
              type='button'
              className='btn-standard'
              onClick={() => handleDelete(modal.data)}
            >
              Ja
            </button>
            <button
              type='button'
              className='btn-standard'
              onClick={() => {
                setModal({
                  open: false,
                  title: 'Event entfernen',
                  id: ''
                });
              }}
            >
              Nein
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VirtualWineTasting;
