/* eslint-disable no-useless-escape */
const regularExpressions = {
  name: /[^0-9\.\,\"\?\!\;\:\#\$\%\&\(\)\*\+\-\/\<\>\=\@\[\]\\\^\_\{\}\|\~]+/,
  surname:
    /[^0-9\.\,\"\?\!\;\:\#\$\%\&\(\)\*\+\-\/\<\>\=\@\[\]\\\^\_\{\}\|\~]+/,
  email:
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
  street: /([a-z ]{2,}\s{0,1}\d{0,3})/,
  house_number: /(((\S+)?)((-|\|\/|\S)?)\d+(-|\/|\S)?((\w|\d)+)?)/,
  city: /[A-z]\w+/,
  phone:
    /(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})/,
  date_of_birth:
    /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/
};
// 'zip_code': /(^\d{5,6}$)|(^\d{2}-\d{3,}$)|(^\d{2}\s\d{3,}$)/,

export const checkIfValid = (value, type) => {
  if (type === 'zip_code') {
    return value.length > 3 && value.length < 11;
  } else {
    return regularExpressions[type].test(value);
  }
};
