import React, { useEffect } from 'react';
import './dashboard/dashboard.scss';
import { KeyboardArrowRight } from '@material-ui/icons';
import {Link} from 'react-router-dom';

const Dashboard = () => {
    useEffect(() => {
        document.title = 'BSC CC - Portal';
    }, []);
    return <div id="dashboard">
        <h1>Home</h1>
        <div className="questions-menu">
            <ul>
                <li>
                    <Link to="/virtual-wine-tasting"><h3>Virtual Wine Tasting</h3> <KeyboardArrowRight/></Link>
                </li>
                <li>
                    <Link to="/grundprofil"><h3>Kreativität und Kompetenz – Mein „Grundprofil“</h3> <KeyboardArrowRight/></Link>
                </li>
                <li>
                    <Link to="/fragebogen"><h3>Fragebogen – Unsere inneren Antreiber</h3> <KeyboardArrowRight/></Link>
                </li>
            </ul>
        </div>
    </div>
}

export default Dashboard;