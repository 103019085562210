import React, { useEffect, useState } from 'react';
import { ArrowBackIos, DeleteOutline, Person } from '@material-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import * as _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import './questionnaire.scss';
import { activateLink, removeEmployeeQuestionnaire } from '../../utils/funcs';
import { realtimeDB } from '../../utils/firebase';

const Questionnaire = () => {
  const { currentUser, questionnaires, updateState } = useAuth();
  const [linkOpen, setLinkOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [search, setSearch] = useState('');
  const [modal, setModal] = useState({
    open: false,
    title: 'Mitarbeiter entfernen',
    id: ''
  });

  useEffect(() => {
    setLoaded(true);
    document.title = 'BSC CC - Unsere inneren Antreiber';
    realtimeDB.ref('/').on('value', (snap) => {
      setLinkOpen(snap.val().linkOpen);
    });
    // eslint-disable-next-line
  }, []);

  const handleLinkOpen = (e) => {
    e.preventDefault();
    activateLink(!linkOpen).then(() => {
      updateState();
      realtimeDB.ref('/').on('value', (snap) => {
        setLinkOpen(snap.val().linkOpen);
      });
    });
  };

  const handleRemove = (employeeId) => {
    if (!modal.open) {
      setModal({ ...modal, open: true, id: employeeId });
    } else {
      removeEmployeeQuestionnaire(employeeId).then(() => {
        // updateState();
        setModal({
          open: false,
          title: 'Mitarbeiter entfernen',
          id: ''
        });
      });
    }
  };

  const sortByDate = (array) => {
    array.sort((a, b) => {
      const dateA = a.createdAt;
      const dateB = b.createdAt;
      return dateB - dateA;
    });

    return array;
  };

  return (
    <div id='questionnaire'>
      {currentUser && (
        <Link to={'/dashboard'} className='back-btn' style={{ marginTop: 20 }}>
          <ArrowBackIos /> Zurück
        </Link>
      )}
      <h2>Fragebogen - Unsere inneren Antreiber</h2>
      <div className='options'>
        <input
          className='search'
          type='text'
          placeholder='Suchen...'
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className='open'>
          <h3>Link öffentlich:</h3>
          <div
            className={classnames('toggle-btn', { toggled: linkOpen })}
            onClick={handleLinkOpen}
          >
            <button />
          </div>
        </div>
        <div className='url-link'>
          <textarea
            className={classnames({ active: linkOpen })}
            defaultValue={window.location.origin + '/individuell/fragebogen/'}
            onClick={(e) => {
              e.target.select();
              e.target.setSelectionRange(0, 99999);
              document.execCommand('copy');
              alert('Link wurde kopiert!');
            }}
          />
        </div>
      </div>
      {loaded && (
        <ul className='employees-list'>
          {_.map(sortByDate(questionnaires), (questionnaire, key) => {
            return (
              (_.includes(
                questionnaire.data.name.toLowerCase(),
                search.toLowerCase()
              ) ||
                _.includes(
                  questionnaire.data.surname.toLowerCase(),
                  search.toLowerCase()
                ) ||
                search === '') && (
                <li key={key}>
                  <Person />
                  <div>
                    <h3>
                      {questionnaire.data.name} {questionnaire.data.surname}
                    </h3>
                    <p>
                      {moment(questionnaire.createdAt).format(
                        'DD.MM.YYYY HH:mm'
                      )}
                    </p>
                  </div>
                  <a
                    className='email'
                    href={'mailto:' + questionnaire.data.email}
                  >
                    {questionnaire.data.email}
                  </a>
                  {questionnaire.data.agb && questionnaire.data.summary ? (
                    <Link
                      className='btn-standard'
                      to={
                        '/individuell/fragebogen/' +
                        questionnaire.data.id +
                        '/auswertung'
                      }
                    >
                      Ergebnis anschauen
                    </Link>
                  ) : (
                    <p className='btn-standard disabled'>Kein Ergebnis</p>
                  )}
                  <div
                    className='delete'
                    onClick={(e) => handleRemove(questionnaire.data.id)}
                  >
                    <DeleteOutline className='' disabled />
                  </div>
                </li>
              )
            );
          })}
        </ul>
      )}
      {modal.open && (
        <div className='modal'>
          <h2>{modal.title}</h2>
          <p>Sind Sie sicher, dass Sie diesen Mitarbeiter löschen wollen?</p>
          <div className='btn-group'>
            <button
              type='button'
              className='btn-standard'
              onClick={() => handleRemove(modal.id)}
            >
              Ja
            </button>
            <button
              type='button'
              className='btn-standard'
              onClick={() => {
                setModal({
                  open: false,
                  title: 'Mitarbeiter entfernen',
                  id: ''
                });
              }}
            >
              Nein
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Questionnaire;
