import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRegistered } from '../../contexts/RegisteredContext';
import { useParams } from 'react-router';
import { getEventRegistrationData } from '../../utils/events';
import logo from '../../assets/images/logo_passion-wine_black.png';
import bgImage from '../../assets/images/slide_1.webp';
import packageFirst from '../../assets/images/package_first.png';
import { Helmet } from 'react-helmet';

const title = 'Registration Virtual Wine Tasting - Success';

const RegistrationComplete = () => {
  const { registeredEvent, updateRegisteredEvent } = useRegistered();
  const { companyName, uuid } = useParams();

  useEffect(() => {
    if (!registeredEvent) {
      getEventRegistrationData(
        window.location.origin +
          '/virtual-wine-tasting/' +
          companyName +
          '/' +
          uuid
      ).then((res) => {
        updateRegisteredEvent(res[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {registeredEvent && (
        <div id='registration-success'>
          <div className='header'>
            <img src={logo} alt='Passion & Wine Logo' />
          </div>
          <div className='body'>
            <div className='hero'>
              <img alt='' src={bgImage} />
              <div className='hero-content'>
                {registeredEvent.logo && (
                  <img alt={registeredEvent.name} src={registeredEvent.logo} />
                )}
                <h2>Thank you for your registration!</h2>
              </div>
            </div>
            <div className='success-message'>
              <img alt='Package First' src={packageFirst} />
              <h2>Your personal Wine Package will be delivered soon.</h2>

              <p>
                We're looking forward to seeing you at the event.
                <br />
                Your Passion & Wine Team
              </p>
              <Link
                className='btn-primary-registered'
                to={'/virtual-wine-tasting/' + companyName + '/' + uuid}
              >
                Go back to the registration
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RegistrationComplete;
