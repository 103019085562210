import { firestore, realtimeDB } from './firebase';
import axios from 'axios';

export const addEmployee = async (companyId, employeeData, company) => {
  let dat = null;

  if (companyId && employeeData && company) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .add({
          ...employeeData
        })
        .then((dataRef) => {
          dat = dataRef.id;
          return firestore
            .collection('companies')
            .doc(companyId)
            .collection('employees')
            .doc(dataRef.id)
            .update({
              id: dataRef.id,
              url:
                window.location.origin +
                '/questionnaire/' +
                company.name.toLowerCase().split(' ').join('-') +
                '/' +
                companyId +
                '/' +
                dataRef.id
            });
        })
        .then(() => {
          if (dat) {
            const data = new FormData();
            data.append('name', employeeData.name);
            data.append('surname', employeeData.surname);
            data.append('email', employeeData.email);
            data.append(
              'url',
              window.location.origin +
                '/questionnaire/' +
                company.name.toLowerCase().split(' ').join('-') +
                '/' +
                companyId +
                '/' +
                dat
            );
            sendInvite(data);
          }
        });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const removeEmployee = async (companyId, employeeId) => {
  if (companyId && employeeId) {
    try {
      await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .doc(employeeId)
        .delete();
    } catch (err) {
      alert(err.message);
    }
  }
};

export const getEmployee = async (companyId, employeeId) => {
  if (companyId && employeeId) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .doc(employeeId)
        .get()
        .then((doc) => doc.data());
    } catch (err) {
      alert(err.message);
    }
  }
};

export const getEmployees = async (companyId) => {
  if (companyId) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .get()
        .then((response) => {
          const employees = [];
          response.forEach((doc) =>
            employees.push({
              ...doc.data(),
              invited: doc.data().invited.toDate()
            })
          );
          return employees;
        });
    } catch (err) {
      alert(err);
    }
  }
};

export const addCompany = async (data) => {
  if (data) {
    try {
      await firestore.collection('companies').add({
        ...data
      });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const removeCompany = async (companyId) => {
  if (companyId) {
    try {
      await firestore.collection('companies').doc(companyId).delete();
    } catch (err) {
      alert(err.message);
    }
  }
};

export const getCompany = async (companyId) => {
  if (companyId) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .get()
        .then((response) => {
          return response.data();
        });
    } catch (err) {
      alert(err);
    }
  }
};

export const checkPass = async (companyId, employeeId, pass, agb) => {
  if (companyId && employeeId && pass && agb) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .doc(employeeId)
        .get()
        .then((doc) => {
          if (doc.data().pass === pass) {
            firestore
              .collection('companies')
              .doc(companyId)
              .collection('employees')
              .doc(employeeId)
              .update({ agb: agb });
            return true;
          }
        });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const sendAnswer = async (companyId, employeeId, data) => {
  if (companyId && employeeId && data) {
    const calc = {
      A: 0,
      B: 0,
      C: 0,
      D: 0
    };
    let X = 0;
    let Y = 0;

    calc.A =
      data['an-task'] +
      data['solutions'] +
      data['tech-sit'] +
      data['new-method'] +
      data['math'] +
      data['logic'] +
      data['ra-work'] +
      data['fact-work'];
    calc.B =
      data['ad-task'] +
      data['planing'] +
      data['or-tasks'] +
      data['finances'] +
      data['controll'] +
      data['work-details'] +
      data['risico'] +
      data['deatils-warn'];
    calc.C =
      data['idea'] +
      data['write'] +
      data['contact'] +
      data['learning'] +
      data['empathie'] +
      data['contacting'] +
      data['feeling-tasks'] +
      data['group-talk'];
    calc.D =
      data['concept'] +
      data['co-sit'] +
      data['new-init'] +
      data['creativity'] +
      data['imagination'] +
      data['new-create'] +
      data['details'] +
      data['combination'];

    X = calc.A + calc.B + calc.C + calc.D;
    Y = 300 / X;

    calc.A = calc.A * Y;
    calc.B = calc.B * Y;
    calc.C = calc.C * Y;
    calc.D = calc.D * Y;

    try {
      await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .doc(employeeId)
        .update({
          answered: {
            answer: true,
            data: new Date()
          },
          data: { ...data },
          stats: calc
        });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const blockLink = async (companyId, employeeId) => {
  if (companyId && employeeId) {
    try {
      await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .doc(employeeId)
        .update(
          {
            deactivatedLink: {
              deactivated: true,
              deactivatedAt: new Date()
            }
          },
          (err) => {
            if (!err) {
              return true;
            }
          }
        );
    } catch (err) {
      alert(err.message);
    }
  }
};

export const sendInvite = async (data) => {
  const url = process.env.REACT_APP_EMAIL_URL + '/mail.php';
  if (data) {
    try {
      await axios.post(url, data).then((res) => true);
    } catch (err) {
      alert(err.message);
    }
  }
};

export const activateLink = async (data) => {
  try {
    await realtimeDB.ref('/').set({
      linkOpen: data
    });
  } catch (err) {
    alert(err.message);
  }
};

export const addQuestionnaire = async (data) => {
  if (data) {
    try {
      return await firestore
        .collection('questionnaires')
        .add({
          ...data,
          answered: false,
          verified: false,
          createdAt: new Date()
        })
        .then((dataRef) => {
          const id = dataRef.id;
          firestore.collection('questionnaires').doc(id).update({
            id
          });
          return id;
        });
    } catch (err) {
      alert(err.message);
    }
  }
};
export const checkVerification = async (id) => {
  if (id) {
    try {
      return await firestore
        .collection('questionnaires')
        .doc(id)
        .get()
        .then((doc) => doc.data().verified);
    } catch (err) {
      alert(err.message);
    }
  }
};

export const setVerification = async (id) => {
  if (id) {
    try {
      return await firestore
        .collection('questionnaires')
        .doc(id)
        .update({ verified: true });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const sendIndividualAnswer = async (id, data) => {
  const bePerfect =
    data['1'].score +
    data['8'].score +
    data['11'].score +
    data['13'].score +
    data['23'].score +
    data['24'].score +
    data['33'].score +
    data['38'].score +
    data['43'].score +
    data['47'].score;
  const hurryUp =
    data['3'].score +
    data['12'].score +
    data['14'].score +
    data['19'].score +
    data['21'].score +
    data['27'].score +
    data['32'].score +
    data['39'].score +
    data['42'].score +
    data['48'].score;
  const giveYourBest =
    data['5'].score +
    data['6'].score +
    data['10'].score +
    data['18'].score +
    data['25'].score +
    data['29'].score +
    data['34'].score +
    data['37'].score +
    data['44'].score +
    data['50'].score;
  const doItAllJustice =
    data['2'].score +
    data['7'].score +
    data['15'].score +
    data['17'].score +
    data['28'].score +
    data['30'].score +
    data['35'].score +
    data['36'].score +
    data['45'].score +
    data['46'].score;
  const beStrong =
    data['4'].score +
    data['9'].score +
    data['16'].score +
    data['20'].score +
    data['22'].score +
    data['26'].score +
    data['31'].score +
    data['40'].score +
    data['41'].score +
    data['49'].score;

  if (id && data) {
    try {
      await firestore
        .collection('questionnaires')
        .doc(id)
        .update({
          answers: data,
          summary: {
            '1-bePerfect': {
              name: '„Sei perfekt“',
              score: bePerfect
            },
            '2-hurryUp': {
              name: '„Beeil Dich“',
              score: hurryUp
            },
            '3-giveYourBest': {
              name: '„Streng Dich an“',
              score: giveYourBest
            },
            '4-doItAllJustice': {
              name: '„Mach es allen recht“ / „Sei gefällig“',
              score: doItAllJustice
            },
            '5-beStrong': {
              name: '„Sei stark“',
              score: beStrong
            }
          }
        });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const getSummary = async (id) => {
  if (id) {
    try {
      return await firestore
        .collection('questionnaires')
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.data().agb && doc.data().answers) {
            return {
              summary: doc.data().summary,
              name: doc.data().name,
              surname: doc.data().surname
            };
          }
        });
    } catch (err) {
      alert(err.message);
    }
  }
};

export const checkIfOpenLinkExist = async (companyId) => {
  if (companyId) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .get()
        .then((doc) => {
          if (doc.exists && doc.get('linkOpen') === undefined) {
            firestore
              .collection('companies')
              .doc(companyId)
              .update({ linkOpen: false });
          } else {
            return doc.data().linkOpen;
          }
        });
    } catch (err) {
      alert(err.message);
    }
  }
};
export const toggleOpenLinkGround = async (companyId, linkOpen) => {
  if (companyId) {
    try {
      await firestore
        .collection('companies')
        .doc(companyId)
        .update({ linkOpen: !linkOpen });
    } catch (err) {
      alert(err.message);
    }
  }
};
export const addEmployeeFromLink = async (
  companyId,
  employeeData,
  companyName
) => {
  if (companyId && companyName && employeeData) {
    try {
      return await firestore
        .collection('companies')
        .doc(companyId)
        .collection('employees')
        .add({
          ...employeeData,
          invited: new Date()
        })
        .then((dataRef) => {
          firestore
            .collection('companies')
            .doc(companyId)
            .collection('employees')
            .doc(dataRef.id)
            .update({
              id: dataRef.id,
              url:
                window.location.origin +
                '/questionnaire/' +
                companyName +
                '/' +
                companyId +
                '/' +
                dataRef.id
            });
          return dataRef.id;
        });
    } catch (err) {
      alert(err.message);
    }
  }
};
export const removeEmployeeQuestionnaire = async (employeeId) => {
  if (employeeId) {
    try {
      await firestore.collection('questionnaires').doc(employeeId).delete();
    } catch (err) {
      alert(err.message);
    }
  }
};
