import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getSummary } from '../../utils/funcs';
import * as _ from 'lodash';
import './questionnaire.scss';
import { ArrowBackIos, Print, PrintOutlined } from '@material-ui/icons';
import { useAuth } from '../../contexts/AuthContext';
import { realtimeDB } from '../../utils/firebase';
import { useTranslation } from 'react-i18next';

const Answer = () => {
  const { id } = useParams();
  const [summary, setSummary] = useState();
  const [fullname, setFullname] = useState();
  const { currentUser } = useAuth();
  const [linkOpen, setLinkOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getSummary(id).then((value) => {
      setSummary(value.summary);
      setFullname(value.name + ' ' + value.surname);
    });
    document.title = 'BSC CC - Unsere inneren Antreiber';
    realtimeDB.ref('/').on('value', (snap) => {
      setLinkOpen(snap.val().linkOpen);
    });
    // eslint-disable-next-line
  }, []);
  const sortByKey = (obj) => {
    const sorted = {};
    Object.keys(obj)
      .sort()
      .forEach(function (key) {
        sorted[key] = obj[key];
      });
    return sorted;
  };
  return (!currentUser && linkOpen) || currentUser ? (
    <div id='answer' style={{ position: 'relative' }}>
      {!currentUser ? (
        <Link
          to={'/individuell/fragebogen/' + id + '/fragen'}
          className='back-btn'
          style={{ marginTop: 20 }}
        >
          <ArrowBackIos /> {t('Back to the questionnaire')}
        </Link>
      ) : (
        <Link to={'/fragebogen'} className='back-btn' style={{ marginTop: 20 }}>
          <ArrowBackIos /> {t('Back to the questionnaire')}
        </Link>
      )}
      <button
        className='btn-secondary btn-print'
        onClick={() => {
          document.title = `${fullname} - ${
            i18n.language === 'de'
              ? 'Unsere inneren Antreiber - DE'
              : 'Our inner drivers - EN'
          }`;
          window.print();
        }}
        style={{ position: 'absolute', top: -10, right: 0 }}
      >
        <PrintOutlined style={{ stroke: 'none', fontSize: 18 }} /> {t('Print')}
      </button>
      <span className='pre-title'>{t('PORTAL FOR TRAINING PARTICIPANTS')}</span>
      <h2>
        {t('Evaluation for')} {fullname}
      </h2>
      <p>
        {t(
          'Where you have the highest scores ought to be the strongest driver in your life.'
        )}
      </p>
      {summary && (
        <ul>
          {_.map(sortByKey(summary), (answer, key) => {
            return (
              <li key={key}>
                <h3>
                  {t(answer.name, { fallbackLng: 'de' })}{' '}
                  <span>{t('Questions')}</span>
                </h3>
                <h2 className='answer-score'>{answer.score}</h2>
              </li>
            );
          })}
        </ul>
      )}
      <span>
        {t('Based on Kälin/Müri “Sich und andere führen” Ott Verlag, Thun.')}
      </span>
    </div>
  ) : (
    <h2 className='deactivated'>{t('Evaluation is disabled.')}</h2>
  );
};

export default Answer;
