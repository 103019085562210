import React from 'react';
import {useAuth} from '../../contexts/AuthContext';
import {Redirect, useHistory} from 'react-router-dom';

const Login = () => {
    const history = useHistory();
    const {signIn, currentUser, companies} = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {email, password} = e.target.elements;

        if (email && password) {
            try {
                await signIn(email.value, password.value);
                history.push('/dashboard')
            } catch(err) {
                alert(err.message);
            }
        }
    }

    if (currentUser && companies) {
        return <Redirect to="/dashboard"/>
    } else {
        return <div id="login">
            <h1>Loggen Sie sich ein</h1>
            <form onSubmit={handleSubmit}>
                <input type="email" name="email" placeholder="Email"/>
                <input type="password" name="password" placeholder="Passwort"/>
                <button type="submit" className="btn-accept">Log in</button>
            </form>
        </div>
    }

}

export default Login;