import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { KeyboardArrowDown } from '@mui/icons-material';
import { getEventRegistrationData, registerToEvent } from '../../utils/events';
import logo from '../../assets/images/logo_passion-wine_black.png';
import templateLogo from '../../assets/images/passion_wine_template_logo.png';
import wineAsset from '../../assets/images/registration_bg.jpg';
import moment from 'moment';
import classnames from 'classnames';
import { checkIfValid } from '../../utils/validation';
import * as _ from 'lodash';
import './registration.scss';
import { countries } from '../../assets/data/countries';
import { Check } from '@material-ui/icons';
import { useRegistered } from '../../contexts/RegisteredContext';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from 'react-google-recaptcha';
import momenTZ from 'moment-timezone';

const title = 'Registration Virtual Wine Tasting';

const Registration = () => {
  const history = useHistory();
  const recaptchaRef = useRef();
  const { companyName, uuid } = useParams();
  const { updateRegisteredEvent } = useRegistered();
  const [event, setEvent] = useState(null);
  const [openSelectBox, setOpenSelectBox] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [validated, setValidated] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState(false);
  const [radio, setRadio] = useState({
    complet: true,
    alcohol_free: false
  });
  const [selectedCountry, setSelectedCountry] = useState(null);
  const toValidate = [
    'name',
    'surname',
    'email',
    'street',
    'house_number',
    'zip_code',
    'city'
  ];
  const [validation, setValidation] = useState({
    name: undefined,
    surname: undefined,
    email: undefined,
    // date_of_birth: undefined,
    street: undefined,
    house_number: undefined,
    zip_code: undefined,
    city: undefined,
    phone: undefined,
    country: undefined,
    company_name: undefined,
    data_privacy: false
  });
  const [data, setData] = useState({
    name: undefined,
    surname: undefined,
    email: undefined,
    // date_of_birth: undefined,
    street: undefined,
    house_number: undefined,
    zip_code: undefined,
    city: undefined,
    phone: false,
    country: undefined,
    company_name: false,
    home_address: false,
    complet: false,
    alcohol_free: false,
    cover_costs: false,
    data_privacy: undefined
  });
  const [homeAddress, setHomeAddress] = useState(false);

  useEffect(() => {
    getEventRegistrationData(
      window.location.origin +
        '/virtual-wine-tasting/' +
        companyName +
        '/' +
        uuid
    ).then((res) => {
      setEvent(res[0]);
      updateRegisteredEvent(res[0]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      validated &&
      _.filter(validation, (e, key) => {
        return !e || !_.isUndefined(e);
      }).length > 0
    ) {
      setErrMessage(
        'Please fill out all required fields and accept our data protection policy.'
      );
    } else {
      setErrMessage(false);
    }
  }, [validation, validated]);

  const check = async (elements, company_name, phone) => {
    try {
      Array.from(elements).map((el, key) => {
        if (_.includes(toValidate, el.name)) {
          return setValidation((old) => ({
            ...old,
            [el.name]: checkIfValid(el.value, el.name)
          }));
        }
        return false;
      });
      setValidation((old) => ({
        ...old,
        country: selectedCountry && selectedCountry.value.length > 0
      }));
      setValidation((old) => ({
        ...old,
        data_privacy: data.data_privacy
      }));
      if (!homeAddress) {
        setValidation((old) => ({
          ...old,
          company_name: company_name.value.length > 0
        }));
      } else {
        setValidation((old) => ({ ...old, company_name: false }));
      }
      if (selectedCountry && selectedCountry.code !== 'DE') {
        setValidation((old) => ({
          ...old,
          phone: checkIfValid(phone.value, 'phone')
        }));
      }
      // DATE OF BIRTH
      // setValidation((old) => ({
      //   ...old,
      //   date_of_birth: data.date_of_birth
      //     ? data.date_of_birth
      //     : false,
      // }));

      return validation;
    } catch (err) {
      alert(err.message);
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { company_name, phone } = e.target.elements;
    setValidated(true);
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();

    check(e.target.elements, company_name, phone).then((valid) => {
      if (
        (_.filter(validation, (e, key) => !e).length === 0 && !homeAddress) ||
        (homeAddress &&
          _.filter(validation, (e, key) => !e && key !== 'company_name')
            .length === 0)
      ) {
        if (data.data_privacy && token) {
          setLoadingMsg(true);
          registerToEvent(data, event.id).then(() => {
            history.push(
              '/virtual-wine-tasting/' + companyName + '/' + uuid + '/success'
            );
          });
        }
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div id='registration'>
        <div className='header'>
          <img src={logo} alt='Passion & Wine Logo' />
        </div>
        {event && (
          <div className='body'>
            <div className='hero'>
              <img
                src={event.asset_image ? event.asset_image : wineAsset}
                alt='Passion & Wine - Asset'
              />
              <div className='hero-content'>
                {event.logo ? (
                  <img src={event.logo} alt={event.name} />
                ) : (
                  <img
                    src={templateLogo}
                    alt='Passion & Wine'
                    style={{ height: 180 }}
                  />
                )}
                <h2>
                  Registration for your Virtual Wine Tasting on{' '}
                  {moment(
                    momenTZ(event.dateStart).tz(momenTZ.tz.guess())
                  ).format('MMMM Do')}{' '}
                  at{' '}
                  {moment(
                    momenTZ(event.dateStart).tz(momenTZ.tz.guess())
                  ).format(
                    new Intl.DateTimeFormat(navigator.language, {
                      hour: 'numeric'
                    }).resolvedOptions().hour12
                      ? 'h:mm a'
                      : 'HH:mm'
                  )}{' '}
                  ({momenTZ().tz(momenTZ.tz.guess()).format('z')})
                </h2>
                {/* {event.public && <h3>Sign up for your wine package</h3>} */}
                {/* <h4>An event powered by {event.name}</h4> */}
                {event.public && (
                  <h3>
                    Please sign up by{' '}
                    {moment(
                      momenTZ(event.dateEnd).tz(momenTZ.tz.guess())
                    ).format('MMMM Do')}{' '}
                    to make sure your wine package will be delivered on time.
                  </h3>
                )}
                {event.public && (
                  <h4>
                    Your data will be encrypted and will only be used for the
                    shipping of your personal wine package.
                  </h4>
                )}
              </div>
            </div>
            {event.public ? (
              <div className='registration-form'>
                {event.slogan && (
                  <div className='slogans-container'>
                    <h2>{event.slogan}</h2>
                    {event.slogan_text && <p>{event.slogan_text}</p>}
                  </div>
                )}
                {event.greeting && (
                  <div className='greetings-container'>
                    <h2>{event.greeting}</h2>
                    {event.greeting_text && <p>{event.greeting_text}</p>}
                  </div>
                )}
                {errMessage && <p className='error-msg'>{errMessage}</p>}
                <form onSubmit={handleRegister}>
                  <label>
                    <p>Name *</p>
                    <input
                      type='text'
                      name='name'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.name) && !validation.name
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  <label>
                    <p>Surname *</p>
                    <input
                      type='text'
                      name='surname'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.surname) &&
                          !validation.surname
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  <label>
                    <p>Email *</p>
                    <input
                      type='email'
                      name='email'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.email) && !validation.email
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  {/* <label>
                    <p>Date of birth *</p>
                    <DateTimePicker
                      onChange={(value) => {
                        setValidation((old) => ({
                          ...old,
                          date_of_birth:
                            Object.prototype.toString.call(value) ===
                              '[object Date]' && !isNaN(value),
                        }));
                        setData((old) => ({
                          ...old,
                          date_of_birth: value,
                        }));
                      }}
                      value={data.date_of_birth}
                      className={classnames(
                        {
                          'has-error':
                            !_.isUndefined(
                              validation.date_of_birth
                            ) && !validation.date_of_birth,
                        },
                        'datepicker'
                      )}
                      calendarIcon={
                        <Event className="calendar-icon" />
                      }
                      disableClock={true}
                      clearIcon={null}
                      format="MM/dd/yyyy"
                    />
                  </label> */}
                  <p className='sub-title'>Address</p>
                  <label>
                    <p>Street *</p>
                    <input
                      type='text'
                      name='street'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.street) &&
                          !validation.street
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  <label>
                    <p>House number *</p>
                    <input
                      type='text'
                      name='house_number'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.house_number) &&
                          !validation.house_number
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  <label>
                    <p>Zip Code *</p>
                    <input
                      type='text'
                      name='zip_code'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.zip_code) &&
                          !validation.zip_code
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  <label>
                    <p>City *</p>
                    <input
                      type='text'
                      name='city'
                      className={classnames({
                        'has-error':
                          !_.isUndefined(validation.city) && !validation.city
                      })}
                      onChange={(e) => {
                        setValidation((old) => ({
                          ...old,
                          [e.target.name]: checkIfValid(
                            e.target.value,
                            e.target.name
                          )
                        }));
                        setData((old) => ({
                          ...old,
                          [e.target.name]: e.target.value
                        }));
                      }}
                    />
                  </label>
                  <label>
                    <p>Country *</p>
                    <div
                      onClick={() => setOpenSelectBox(!openSelectBox)}
                      className={classnames('select-input', {
                        'has-error':
                          !_.isUndefined(validation.country) &&
                          !validation.country
                      })}
                    >
                      <p className='selected-country'>
                        {selectedCountry
                          ? selectedCountry.value
                          : 'Select country'}
                      </p>
                      <KeyboardArrowDown />
                    </div>
                    <ul
                      className={classnames('select-box', {
                        open: openSelectBox
                      })}
                    >
                      {countries.map((country, key) => (
                        <li
                          key={key}
                          onClick={() => {
                            setSelectedCountry(country);
                            setOpenSelectBox(false);
                            setValidation((old) => ({
                              ...old,
                              country: true
                            }));
                            setData((old) => ({
                              ...old,
                              country: country
                            }));
                            if (country.code === 'DE') {
                              setData((old) => ({
                                ...old,
                                phone: ''
                              }));
                              setValidation((old) => ({
                                ...old,
                                phone: true
                              }));
                            } else {
                              setValidation((old) => ({
                                ...old,
                                phone: false
                              }));
                            }
                          }}
                        >
                          {country.value}
                        </li>
                      ))}
                    </ul>
                  </label>
                  {selectedCountry && selectedCountry.code !== 'DE' && (
                    <label>
                      <p>Phone *</p>
                      <input
                        type='tel'
                        name='phone'
                        className={classnames({
                          'has-error':
                            !_.isUndefined(validation.phone) &&
                            !validation.phone
                        })}
                        onChange={(e) => {
                          setValidation((old) => ({
                            ...old,
                            phone: checkIfValid(e.target.value, 'phone')
                          }));
                          setData((old) => ({
                            ...old,
                            phone: e.target.value
                          }));
                        }}
                      />
                    </label>
                  )}
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      name='home_address'
                      onChange={(e) => {
                        setHomeAddress(e.target.checked);
                        setData((old) => ({
                          ...old,
                          home_address: e.target.checked
                        }));
                        if (e.target.checked) {
                          setData((old) => ({
                            ...old,
                            company_name: false
                          }));
                          setValidation((old) => ({
                            ...old,
                            company_name: true
                          }));
                        } else {
                          setData((old) => ({
                            ...old,
                            company_name: undefined
                          }));
                          setValidation((old) => ({
                            ...old,
                            company_name: false
                          }));
                        }
                      }}
                    />
                    <div htmlFor='home_address'>
                      <Check />
                    </div>
                    <p>The provided address is my home address</p>
                  </label>
                  {!homeAddress && (
                    <label>
                      <p>Company name *</p>
                      <input
                        type='text'
                        name='company_name'
                        className={classnames({
                          'has-error':
                            !_.isUndefined(validation.company_name) &&
                            !validation.company_name
                        })}
                        onChange={(e) => {
                          setValidation((old) => ({
                            ...old,
                            company_name: e.target.value.length > 0
                          }));
                          setData((old) => ({
                            ...old,
                            company_name: e.target.value
                          }));
                        }}
                      />
                    </label>
                  )}
                  <p className='description'>
                    In case you provided your personal home address, please
                    consider the compliance guidelines of your company.
                  </p>
                  <hr />
                  <label className='radio-select'>
                    <input
                      type='radio'
                      name='complet'
                      id='complet'
                      checked={radio.complet}
                      onChange={() => {
                        setRadio({
                          complet: true,
                          alcohol_free: false
                        });
                        setData((old) => ({
                          ...old,
                          complet: true,
                          alcohol_free: false
                        }));
                      }}
                    />
                    <div htmlFor='complet' />
                    <p>Please send me my complementary wine selection</p>
                  </label>
                  <label className='radio-select'>
                    <input
                      type='radio'
                      name='alcohol_free'
                      id='alcohol_free'
                      checked={radio.alcohol_free}
                      onChange={() => {
                        setRadio({
                          complet: false,
                          alcohol_free: true
                        });
                        setData((old) => ({
                          ...old,
                          complet: false,
                          alcohol_free: true
                        }));
                      }}
                    />
                    <div htmlFor='alcohol_free' />
                    <p>Please send me an alcohol free wine package</p>
                  </label>
                  <hr />
                  {event.compliance && (
                    <label className='checkbox'>
                      <input
                        type='checkbox'
                        name='cover_costs'
                        onChange={(e) =>
                          setData((old) => ({
                            ...old,
                            cover_costs: e.target.checked
                          }))
                        }
                      />
                      <div htmlFor='cover_costs'>
                        <Check />
                      </div>
                      <p>
                        For compliance reasons I’ll cover the costs of 35€ for
                        the wine package myself
                      </p>
                    </label>
                  )}
                  {event.compliance && <hr />}
                  <label className='checkbox'>
                    <input
                      type='checkbox'
                      name='data_privacy'
                      onChange={(e) => {
                        setData((old) => ({
                          ...old,
                          data_privacy: e.target.checked
                        }));
                        setValidation((old) => ({
                          ...old,
                          data_privacy: e.target.checked
                        }));
                        if (!e.target.checked) {
                          document.getElementById('set-err').style.borderColor =
                            'red';
                        } else {
                          document.getElementById('set-err').style.borderColor =
                            'transparent';
                        }
                      }}
                    />
                    <div htmlFor='data_privacy' id='set-err'>
                      <Check />
                    </div>
                    <p>
                      I hereby agree that my data is stored and transfered to
                      the shipping service provider. I find more information in
                      the{' '}
                      <a
                        className='link'
                        href='https://passion-wine.de/pages/datenschutzerklarung'
                        target={'blank'}
                      >
                        data protection policy
                      </a>
                      .
                    </p>
                  </label>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size='invisible'
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  />
                  <button
                    className={classnames({
                      disabled: !data.data_privacy && !loadingMsg
                    })}
                    disabled={loadingMsg}
                    onClick={(e) => {
                      if (!data.data_privacy) {
                        document.getElementById('set-err').style.borderColor =
                          'red';
                      } else {
                        document.getElementById('set-err').style.borderColor =
                          'transparent';
                      }
                    }}
                  >
                    {loadingMsg ? 'LOADING...' : 'ORDER WINE PACKAGE'}
                  </button>
                  {event.textFields.map((text, key) => (
                    <p className='description' key={key}>
                      {text.description}
                    </p>
                  ))}
                </form>
              </div>
            ) : (
              <div className='registration-form offline'>
                <h2>We are sorry</h2>
                <h3>
                  You missed to registrate on time.
                  <br />
                  The registration was closed on{' '}
                  {moment(event.dateEnd).format('Do')} of{' '}
                  {moment(event.dateEnd).format('MMMM YYYY')}
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Registration;
