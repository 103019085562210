import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firebase-firestore';
import 'firebase/firebase-database';
import 'firebase/firebase-storage';
// import 'firebase/app-check';
import {firebaseConfig} from './config';

const app = firebase.initializeApp(firebaseConfig);
// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//   process.env.REACT_APP_RECAPTCHA_SECRET_V3,

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   true);

export const auth = app.auth();
export const firestore = firebase.firestore();
export const realtimeDB = firebase.database();
export const storage = firebase.storage();
export default app;