import { Add, ArrowBackIos } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { addCompany } from '../../utils/funcs';
import CompaniesList from '../dashboard/companyList.component';
import {Link} from 'react-router-dom';

const GroundProfile = () => {
    const {currentUser, updateCompanies} = useAuth();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
        document.title = 'BSC CC - Grundprofil';
    }, []);
    useEffect(() => {
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        const {name} = e.target.elements;

        if (name.value) {
            const data = {
                'name': name.value,
                'createdAt': new Date()
            }
            addCompany(data).then(() => {
                updateCompanies();
                document.getElementById('add-company-form').reset();
            });
        }
    }

    return <div id="ground-profile">
        {currentUser && <Link to={'/dashboard'} className="back-btn" style={{marginTop: 20}}><ArrowBackIos/> Zurück</Link>}
    	<h2>Firmen</h2>
        <button className="btn-standard" type="button" onClick={(e) => {
            e.target.nextSibling.classList.toggle('open');
            e.target.classList.toggle('open');
        }}>
            <Add/> Hinzufügen
        </button>
        <div className="form">
            <form onSubmit={handleSubmit} id="add-company-form">
                <label>
                    <b>Firma Name:</b>
                    <input type="text" name="name"/>
                </label>
                <button className="btn-accept" type="submit">Hinzufügen</button>
            </form>
        </div>
        {loaded && <CompaniesList/>}
    </div>
}

export default GroundProfile;