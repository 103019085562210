import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import './questionnaire.scss';
import { useHistory, useParams } from 'react-router-dom';
import { sendIndividualAnswer } from '../../utils/funcs';
import { realtimeDB } from '../../utils/firebase';
import { useTranslation } from 'react-i18next';

const SecondQuestions = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [linkOpen, setLinkOpen] = useState(false);
  const [stats, setStats] = useState({
    1: {
      name: 'Whenever I do a job, I do it extremely thoroughly. There must be no mistakes.',
      score: 0
    },
    2: {
      name: 'I am responsible for ensuring that those who interact with me feel comfortable.',
      score: 0
    },
    3: {
      name: 'I am constantly on the go. Everything has to go fast.',
      score: 0
    },
    4: {
      name: "I don't like to show my weaknesses to others. I appear strong, just show no weakness.",
      score: 0
    },
    5: { name: 'My motto: “If you rest, you rust.”', score: 0 },
    6: {
      name: "I often need the sentence: “It's not that easy to say.”",
      score: 0
    },
    7: {
      name: 'I often say and do more than is actually necessary.',
      score: 0
    },
    8: {
      name: "I tend to reject people who don't work properly enough.",
      score: 0
    },
    9: { name: 'Showing feelings means showing weakness.', score: 0 },
    10: {
      name: 'Don’t let go, that’s my motto.',
      score: 0
    },
    11: {
      name: 'When I express an opinion, I also justify it, because exuding competence is very important to me.',
      score: 0
    },
    12: {
      name: 'If I have a wish, I will fulfill it very quickly.',
      score: 0
    },
    13: {
      name: 'I only deliver the report after I have revised it several times so that it is perfect.',
      score: 0
    },
    14: {
      name: 'People who “dawdle” upset me because time is money.',
      score: 0
    },
    15: {
      name: 'It is important for me to be accepted by the others. I therefore put my interests on the back burner.',
      score: 0
    },
    16: {
      name: 'I have a hard shell and a soft core. But you can’t show that in the professional world.',
      score: 0
    },
    17: {
      name: 'I try to find out what others expect me to do, because I want others to love me.',
      score: 0
    },
    18: {
      name: 'I find it difficult to understand people who live a carefree life day-to-day, because you have to work hard to achieve success.',
      score: 0
    },
    19: {
      name: 'I interrupt people who don’t get to the point more often because my time is precious.',
      score: 0
    },
    20: { name: 'I solve my problems myself!', score: 0 },
    21: {
      name: 'I complete tasks quickly because there is a lot to do.',
      score: 0
    },
    22: {
      name: 'I am aloof when interacting with others. Too much closeness diminishes authority.',
      score: 0
    },
    23: {
      name: "I ought to complete my tasks even better because they still aren't perfect enough.",
      score: 0
    },
    24: {
      name: 'I also personally take care of ancillary matters so that everything really works.',
      score: 0
    },
    25: {
      name: "Success doesn't fall from the sky; I have to work hard to achieve it. “In the sweat of your brow.”",
      score: 0
    },
    26: { name: 'I have little understanding for stupid mistakes.', score: 0 },
    27: {
      name: 'It is important to me that questions are answered briefly and concisely.',
      score: 0
    },
    28: {
      name: 'It is important to me to find out from others whether I’ve done my job well, because I need others’ confirmation that I am OK.',
      score: 0
    },
    29: {
      name: 'When I start a task, I always finish it.',
      score: 0
    },
    30: {
      name: 'I put the wishes and needs of others before my own. I really want to be accepted.',
      score: 0
    },
    31: {
      name: 'I am often hard on others so as not to be hurt by them. Too much closeness is dangerous.',
      score: 0
    },
    32: {
      name: 'When things are going too slowly for me, I often impatiently drum my fingers on the table.',
      score: 0
    },
    33: {
      name: 'When explaining facts, I like to use a clear list: “First…, second…, third…,” or “On the other hand…”',
      score: 0
    },
    34: {
      name: 'I think most things are not as easy as many people think. They definitely take the easy way out.',
      score: 0
    },
    35: {
      name: 'I’m uncomfortable criticizing other people because then they might no longer like me.',
      score: 0
    },
    36: { name: 'I often nod my head in discussions.', score: 0 },
    37: {
      name: 'I strive to achieve my goals. Only what is hard-earned counts.',
      score: 0
    },
    38: {
      name: 'My facial expression is rather serious and concentrated.',
      score: 0
    },
    39: {
      name: 'I am restless, nervous, and sometimes hectic.',
      score: 0
    },
    40: {
      name: 'Nothing can shake me that quickly. I am a bastion of calm.',
      score: 0
    },
    41: {
      name: 'My feelings are not the concern of others. I have to be strong, those who show their feelings are weak.',
      score: 0
    },
    42: {
      name: 'Things almost always move too slowly for me, which is why I push others so that some progress is made.',
      score: 0
    },
    43: {
      name: 'I often say: “Precisely – exactly – of course – naturally – obviously.”',
      score: 0
    },
    44: { name: 'I often say: “I don’t understand that …”', score: 0 },
    45: {
      name: 'I’m more likely to say: “Can’t you just give it a try,” rather than “try it once.” Better safe than sorry.',
      score: 0
    },
    46: {
      name: 'I am diplomatic so that I hardly put anybody off.',
      score: 0
    },
    47: {
      name: 'I try to exceed the expectations placed on me. For me it is important to be better than others.',
      score: 0
    },
    48: {
      name: 'When I’m on the phone, I often work on or do other things on the side.',
      score: 0
    },
    49: {
      name: '“Grit your teeth” is my motto because you have to be brave.',
      score: 0
    },
    50: {
      name: "Despite my tremendous efforts, many things just don't work out for me. But if I really make an effort again then it’ll all be fine.",
      score: 0
    }
  });

  useEffect(() => {
    document.title = 'BSC CC - Unsere inneren Antreiber';
    realtimeDB.ref('/').on('value', (snap) => {
      setLinkOpen(snap.val().linkOpen);
    });
  }, []);

  const handleAnswer = (e) => {
    e.preventDefault();
    let notAll = false;
    _.map(stats, (stat) => {
      if (stat.score === 0) {
        notAll = true;
      }
    });
    if (!notAll) {
      sendIndividualAnswer(id, stats).then(() =>
        history.push('/individuell/fragebogen/' + id + '/auswertung')
      );
    } else {
      alert('Bitte beantworten Sie alle Fragen.');
    }
  };

  return linkOpen ? (
    <div id='second-questions'>
      <span className='pre-title'>{t('PORTAL FOR TRAINING PARTICIPANTS')}</span>
      <h2>{t('Questionnaire - Our inner drivers')}</h2>
      <div className='selects'>
        {_.map(stats, (value, key) => {
          const numbers = [1, 2, 3, 4, 5, 6];
          return (
            <div
              key={key}
              className='select'
              id={'question-' + key}
              style={{
                padding: '10px 15px'
              }}
            >
              <p
                className='label'
                style={{ maxWidth: 600, lineHeight: '1.3', marginRight: 25 }}
              >
                {t(value.name)}
              </p>
              <div className='an'>
                <div className='sub-details'>
                  <span>{t('Not at all')}</span>
                  <span />
                  <span>{t('Full and complete')}</span>
                </div>
                <div className='to-select' style={{ marginLeft: 'auto' }}>
                  {_.map(numbers, (n, i) => {
                    return (
                      <span
                        className='number'
                        key={i}
                        data-point={n}
                        onClick={(e) => {
                          document
                            .querySelectorAll(`#question-${key} .number`)
                            .forEach((el) => el.classList.remove('active'));
                          e.target.classList.add('active');
                          setStats({
                            ...stats,
                            [key]: { ...stats[key], score: n }
                          });
                        }}
                      >
                        {n}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
        <button className='btn-standard' type='button' onClick={handleAnswer}>
          {t('Continue to evaluation')}
        </button>
      </div>
    </div>
  ) : (
    <h2 className='deactivated'>{t('Questionnaire is disabled.')}</h2>
  );
};

export default SecondQuestions;
