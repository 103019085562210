import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
const lngs = {
  de: { nativeName: 'Deutsch', imgUrl: '/assets/images/german.png' },
  en: { nativeName: 'English', imgUrl: '/assets/images/english.png' }
};
const Header = () => {
  const { currentUser, signOut } = useAuth();
  const { i18n } = useTranslation();
  const location = window.location.pathname;

  return (
    <nav id='header'>
      <Link to={'/'} className='logo'>
        <img src={logo} alt='BSC CC - Logo' />
      </Link>
      {(location.includes('/individuell/fragebogen/') ||
        location.includes('/questionnaire')) && (
        <div style={{ display: 'flex', marginLeft: 'auto' }}>
          {Object.keys(lngs).map((lng) => (
            <img
              key={lng}
              type='submit'
              src={lngs[lng].imgUrl}
              onClick={() => {
                i18n.changeLanguage(lng);
              }}
              style={{
                width: '52px',
                cursor: 'pointer',
                borderRadius: '50%',
                objectFit: 'cover',
                transition: 'all 0.3s ease-in-out',
                border: `4px solid ${
                  i18n.language === lng ? '#018db0' : 'transparent'
                }`
              }}
              alt={`BSC CC - Language (${lngs[lng].nativeName})`}
            />
          ))}
        </div>
      )}
      {!currentUser ? (
        <Link to={'/login'}>Login</Link>
      ) : (
        <Link to={'/'} onClick={signOut}>
          Logout
        </Link>
      )}
    </nav>
  );
};

export default Header;
