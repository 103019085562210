import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { realtimeDB } from '../../utils/firebase';

const IndividualQuestions = () => {
    const {id} = useParams();
    const [linkOpen, setLinkOpen] = useState(false);

    useEffect(() => {
        document.title = 'BSC CC - Unsere inneren Antreiber';
        realtimeDB.ref('/').on('value', snap => {
            setLinkOpen(snap.val().linkOpen);
        });
    }, []);
    return linkOpen ? <div id="individual-questions">
        <Redirect to={"/individuell/fragebogen/" + id + "/fragen"}/>
    </div> : <h2 className="deactivated">Fragebogen ist deaktiviert.</h2>
};

export default IndividualQuestions;