import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { realtimeDB } from '../../utils/firebase';
import { addQuestionnaire } from '../../utils/funcs';
import './questionnaire.scss';
import { useTranslation } from 'react-i18next';

const Individual = () => {
  const [linkOpen, setLinkOpen] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, surname, agb } = e.target.elements;
    if (name.value && agb.checked && surname.value) {
      setErrMsg('');
      addQuestionnaire({
        name: name.value,
        surname: surname.value,
        agb: agb.checked
      }).then((id) => {
        history.push(`/individuell/fragebogen/${id}`);
      });
    } else {
      setErrMsg(
        'Bitte geben Sie das korrekte Daten ein und akzeptieren Sie die AGB, um fortzufahren.'
      );
    }
  };

  useEffect(() => {
    realtimeDB.ref('/').on('value', (snap) => {
      setLinkOpen(snap.val().linkOpen);
    });
  }, []);

  return (
    <div id='individual'>
      {linkOpen ? (
        <form id='pass-form' onSubmit={handleSubmit}>
          <h2>{t('Verification')}</h2>
          <p>{t('Please enter your name and surname.')}</p>
          <label>
            <span>{t('Name')}*</span>
            <input type='text' name='name' />
          </label>
          <label>
            <span>{t('Surname')}*</span>
            <input type='text' name='surname' />
          </label>
          <label className='checkbox'>
            <input type='checkbox' name='agb' />
            {i18n.language === 'de' ? (
              <span>
                Ich habe die{' '}
                <a
                  href='https://bsc-cc.de/datenschutzerklaerung/'
                  target='blank'
                >
                  Datenschutzerklärung
                </a>{' '}
                gelesen und akzeptiere diese.
              </span>
            ) : (
              <span>
                I have read and accept the{' '}
                <a
                  href='https://bsc-cc.de/datenschutzerklaerung/'
                  target='blank'
                >
                  Privacy Policy
                </a>{' '}
              </span>
            )}
          </label>
          {errMsg && <p className='error-msg'>{errMsg}</p>}
          <button className='btn-standard'>{t('Save')}</button>
        </form>
      ) : (
        <h2 className='deactivated'>Fragebogen ist deaktiviert.</h2>
      )}
    </div>
  );
};

export default Individual;
