import { firestore, storage } from './firebase';
import { v4 } from 'uuid';
import moment from 'moment';
import * as _ from 'lodash';

export const addEvent = async (data, callback) => {
  const urlDate = moment().format('DD_MM_YYYY_hh_mm');
  try {
    if (data.logo) {
      return await storage
        .ref(
          'events/' +
            data.name.split(' ').join('-').split('.').join('-') +
            '_' +
            urlDate
        )
        .put(data.logo)
        .then((val) => {
          return val.ref.getDownloadURL().then((imageUrl) => {
            if (data.asset_image) {
              return storage
                .ref(
                  'events/asset_image_' +
                    data.name.split(' ').join('-').split('.').join('-') +
                    '_' +
                    urlDate
                )
                .put(data.asset_image)
                .then((assetVal) => {
                  assetVal.ref.getDownloadURL().then((assetUrl) => {
                    return firestore
                      .collection('events')
                      .add({
                        'name': data.name,
                        'description': data.description,
                        'template': data.template,
                        'dateStart': data.dateStart,
                        'dateEnd': data.dateEnd,
                        'textFields': data.textFields,
                        'slogan': data.slogan,
                        'slogan_text': data.slogan_text,
                        'greeting': data.greeting,
                        'greeting_text': data.greeting_text,
                        'logo': imageUrl,
                        'logo_date': urlDate,
                        'asset_image': assetUrl,
                        'asset_image_date': urlDate,
                        'compliance': data.compliance,
                        'register-url':
                          window.location.origin +
                          '/virtual-wine-tasting/' +
                          data.name.split(' ').join('-').toLowerCase() +
                          '/' +
                          v4(),
                        'createdAt': new Date(),
                        'public': true
                      })
                      .then((res) => {
                        return firestore
                          .collection('events')
                          .doc(res.id)
                          .update({ id: res.id })
                          .then(() => true);
                      });
                  });
                });
            } else {
              return firestore
                .collection('events')
                .add({
                  'name': data.name,
                  'description': data.description,
                  'template': data.template,
                  'dateStart': data.dateStart,
                  'dateEnd': data.dateEnd,
                  'textFields': data.textFields,
                  'slogan': data.slogan,
                  'slogan_text': data.slogan_text,
                  'greeting': data.greeting,
                  'greeting_text': data.greeting_text,
                  'logo': imageUrl,
                  'logo_date': urlDate,
                  'asset_image': false,
                  'asset_image_date': urlDate,
                  'compliance': data.compliance,
                  'register-url':
                    window.location.origin +
                    '/virtual-wine-tasting/' +
                    data.name.split(' ').join('-').toLowerCase() +
                    '/' +
                    v4(),
                  'createdAt': new Date(),
                  'public': true
                })
                .then((res) => {
                  return firestore
                    .collection('events')
                    .doc(res.id)
                    .update({ id: res.id })
                    .then(() => true);
                });
            }
          });
        });
    } else {
      if (data.asset_image) {
        return await storage
          .ref(
            'events/asset_image_' +
              data.name.split(' ').join('-').split('.').join('-') +
              '_' +
              urlDate
          )
          .put(data.asset_image)
          .then((assetVal) => {
            assetVal.ref.getDownloadURL().then((assetUrl) => {
              return firestore
                .collection('events')
                .add({
                  'name': data.name,
                  'description': data.description,
                  'template': data.template,
                  'dateStart': data.dateStart,
                  'dateEnd': data.dateEnd,
                  'textFields': data.textFields,
                  'slogan': data.slogan,
                  'slogan_text': data.slogan_text,
                  'greeting': data.greeting,
                  'greeting_text': data.greeting_text,
                  'logo': false,
                  'logo_date': urlDate,
                  'asset_image': assetUrl,
                  'asset_image_date': urlDate,
                  'compliance': data.compliance,
                  'register-url':
                    window.location.origin +
                    '/virtual-wine-tasting/' +
                    data.name.split(' ').join('-').toLowerCase() +
                    '/' +
                    v4(),
                  'createdAt': new Date(),
                  'public': true
                })
                .then((res) => {
                  firestore
                    .collection('events')
                    .doc(res.id)
                    .update({ id: res.id });
                  return true;
                });
            });
          });
      } else {
        return await firestore
          .collection('events')
          .add({
            'name': data.name,
            'description': data.description,
            'template': data.template,
            'dateStart': data.dateStart,
            'dateEnd': data.dateEnd,
            'textFields': data.textFields,
            'slogan': data.slogan,
            'slogan_text': data.slogan_text,
            'greeting': data.greeting,
            'greeting_text': data.greeting_text,
            'logo': false,
            'logo_date': urlDate,
            'asset_image': false,
            'asset_image_date': urlDate,
            'compliance': data.compliance,
            'register-url':
              window.location.origin +
              '/virtual-wine-tasting/' +
              data.name.split(' ').join('-').toLowerCase() +
              '/' +
              v4(),
            'createdAt': new Date(),
            'public': true
          })
          .then((res) => {
            firestore.collection('events').doc(res.id).update({ id: res.id });
            return true;
          });
      }
    }
  } catch (err) {
    alert(err.message);
  }
};
export const updateEvent = async (data, callback) => {
  try {
    if (data.logo && !_.isString(data.logo)) {
      return await storage
        .ref(
          'events/' +
            data.name.split(' ').join('-').split('.').join('-') +
            '_' +
            data.logo_date
        )
        .put(data.logo)
        .then((val) => {
          return val.ref.getDownloadURL().then((imageUrl) => {
            if (data.asset_image && !_.isString(data.asset_image)) {
              return storage
                .ref(
                  'events/asset_image_' +
                    data.name.split(' ').join('-').split('.').join('-') +
                    '_' +
                    data.asset_image_date
                )
                .put(data.asset_image)
                .then((assetVal) => {
                  assetVal.ref.getDownloadURL().then((assetUrl) => {
                    return firestore.collection('events').doc(data.id).update({
                      'id': data.id,
                      'name': data.name,
                      'description': data.description,
                      'template': data.template,
                      'dateStart': data.dateStart,
                      'dateEnd': data.dateEnd,
                      'textFields': data.textFields,
                      'slogan': data.slogan,
                      'slogan_text': data.slogan_text,
                      'greeting': data.greeting,
                      'greeting_text': data.greeting_text,
                      'logo': imageUrl,
                      'logo_date': data.logo_date,
                      'asset_image': assetUrl,
                      'asset_image_date': data.asset_image_date,
                      'compliance': data.compliance,
                      'register-url': data['register-url'],
                      'createdAt': data.createdAt,
                      'updatedAt': new Date()
                    });
                  });
                });
            } else if (data.asset_image && _.isString(data.asset_image)) {
              return firestore.collection('events').doc(data.id).update({
                'id': data.id,
                'name': data.name,
                'description': data.description,
                'template': data.template,
                'dateStart': data.dateStart,
                'dateEnd': data.dateEnd,
                'textFields': data.textFields,
                'slogan': data.slogan,
                'slogan_text': data.slogan_text,
                'greeting': data.greeting,
                'greeting_text': data.greeting_text,
                'logo': imageUrl,
                'logo_date': data.logo_date,
                'asset_image': data.asset_image,
                'asset_image_date': data.asset_image_date,
                'compliance': data.compliance,
                'register-url': data['register-url'],
                'createdAt': data.createdAt,
                'updatedAt': new Date()
              });
            } else {
              return firestore.collection('events').doc(data.id).update({
                'id': data.id,
                'name': data.name,
                'description': data.description,
                'template': data.template,
                'dateStart': data.dateStart,
                'dateEnd': data.dateEnd,
                'textFields': data.textFields,
                'slogan': data.slogan,
                'slogan_text': data.slogan_text,
                'greeting': data.greeting,
                'greeting_text': data.greeting_text,
                'logo': imageUrl,
                'logo_date': data.logo_date,
                'asset_image': false,
                'asset_image_date': data.asset_image_date,
                'compliance': data.compliance,
                'register-url': data['register-url'],
                'createdAt': data.createdAt,
                'updatedAt': new Date()
              });
            }
          });
        });
    } else if (data.logo && _.isString(data.logo)) {
      if (data.asset_image && !_.isString(data.asset_image)) {
        return await storage
          .ref(
            'events/asset_image_' +
              data.name.split(' ').join('-').split('.').join('-') +
              '_' +
              data.asset_image_date
          )
          .put(data.asset_image)
          .then((assetVal) => {
            assetVal.ref.getDownloadURL().then((assetUrl) => {
              return firestore.collection('events').doc(data.id).update({
                'id': data.id,
                'name': data.name,
                'description': data.description,
                'template': data.template,
                'dateStart': data.dateStart,
                'dateEnd': data.dateEnd,
                'textFields': data.textFields,
                'slogan': data.slogan,
                'slogan_text': data.slogan_text,
                'greeting': data.greeting,
                'greeting_text': data.greeting_text,
                'logo': data.logo,
                'logo_date': data.logo_date,
                'asset_image': assetUrl,
                'asset_image_date': data.asset_image_date,
                'compliance': data.compliance,
                'register-url': data['register-url'],
                'createdAt': data.createdAt,
                'updatedAt': new Date()
              });
            });
          });
      } else if (data.asset_image && _.isString(data.asset_image)) {
        return firestore.collection('events').doc(data.id).update({
          'id': data.id,
          'name': data.name,
          'description': data.description,
          'template': data.template,
          'dateStart': data.dateStart,
          'dateEnd': data.dateEnd,
          'textFields': data.textFields,
          'slogan': data.slogan,
          'slogan_text': data.slogan_text,
          'greeting': data.greeting,
          'greeting_text': data.greeting_text,
          'logo': data.logo,
          'logo_date': data.logo_date,
          'asset_image': data.asset_image,
          'asset_image_date': data.asset_image_date,
          'compliance': data.compliance,
          'register-url': data['register-url'],
          'createdAt': data.createdAt,
          'updatedAt': new Date()
        });
      } else {
        return await firestore.collection('events').doc(data.id).update({
          'id': data.id,
          'name': data.name,
          'description': data.description,
          'template': data.template,
          'dateStart': data.dateStart,
          'dateEnd': data.dateEnd,
          'textFields': data.textFields,
          'slogan': data.slogan,
          'slogan_text': data.slogan_text,
          'greeting': data.greeting,
          'greeting_text': data.greeting_text,
          'logo': data.logo,
          'logo_date': data.logo_date,
          'asset_image': false,
          'asset_image_date': data.asset_image_date,
          'compliance': data.compliance,
          'register-url': data['register-url'],
          'createdAt': data.createdAt,
          'updatedAt': new Date()
        });
      }
    } else {
      if (data.asset_image && !_.isString(data.asset_image)) {
        return await storage
          .ref(
            'events/asset_image_' +
              data.name.split(' ').join('-').split('.').join('-') +
              '_' +
              data.asset_image_date
          )
          .put(data.asset_image)
          .then((assetVal) => {
            assetVal.ref.getDownloadURL().then((assetUrl) => {
              return firestore.collection('events').doc(data.id).update({
                'id': data.id,
                'name': data.name,
                'description': data.description,
                'template': data.template,
                'dateStart': data.dateStart,
                'dateEnd': data.dateEnd,
                'textFields': data.textFields,
                'slogan': data.slogan,
                'slogan_text': data.slogan_text,
                'greeting': data.greeting,
                'greeting_text': data.greeting_text,
                'logo': false,
                'logo_date': data.logo_date,
                'asset_image': assetUrl,
                'asset_image_date': data.asset_image_date,
                'compliance': data.compliance,
                'register-url': data['register-url'],
                'createdAt': data.createdAt,
                'updatedAt': new Date()
              });
            });
          });
      } else if (data.asset_image && _.isString(data.asset_image)) {
        return firestore.collection('events').doc(data.id).update({
          'id': data.id,
          'name': data.name,
          'description': data.description,
          'template': data.template,
          'dateStart': data.dateStart,
          'dateEnd': data.dateEnd,
          'textFields': data.textFields,
          'slogan': data.slogan,
          'slogan_text': data.slogan_text,
          'greeting': data.greeting,
          'greeting_text': data.greeting_text,
          'logo': false,
          'logo_date': data.logo_date,
          'asset_image': data.asset_image,
          'asset_image_date': data.asset_image_date,
          'compliance': data.compliance,
          'register-url': data['register-url'],
          'createdAt': data.createdAt,
          'updatedAt': new Date()
        });
      } else {
        return await firestore.collection('events').doc(data.id).update({
          'id': data.id,
          'name': data.name,
          'description': data.description,
          'template': data.template,
          'dateStart': data.dateStart,
          'dateEnd': data.dateEnd,
          'textFields': data.textFields,
          'slogan': data.slogan,
          'slogan_text': data.slogan_text,
          'greeting': data.greeting,
          'greeting_text': data.greeting_text,
          'logo': false,
          'logo_date': data.logo_date,
          'asset_image': false,
          'asset_image_date': data.asset_image_date,
          'compliance': data.compliance,
          'register-url': data['register-url'],
          'createdAt': data.createdAt,
          'updatedAt': new Date()
        });
      }
    }
  } catch (err) {
    alert(err.message);
  }
};
export const deleteEvent = async (data, eventLogo, eventBackground) => {
  try {
    if (data.logo_date) {
      return await firestore
        .collection('events')
        .doc(data.id)
        .delete()
        .then(() => {
          if (data.asset_image) {
            storage
              .ref(
                'events/asset_image_' +
                  data.name.split(' ').join('-').split('.').join('-') +
                  '_' +
                  data.asset_image_date
              )
              .delete();
          }
          storage
            .ref(
              'events/' +
                data.name.split(' ').join('-').split('.').join('-') +
                '_' +
                data.logo_date
            )
            .delete();
        })
        .then(() => true);
    } else {
      return await firestore
        .collection('events')
        .doc(data.id)
        .delete()
        .then(() => true);
    }
  } catch (err) {
    alert(err.message);
  }
};

export const getTemplates = async () => {
  try {
    return await firestore
      .collection('events')
      .where('template', '==', true)
      .get()
      .then((snapshot) => {
        const eventTemplates = [];
        snapshot.forEach((doc) => {
          if (doc.exists) {
            eventTemplates.push({
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              dateStart: doc.data().dateStart.toDate(),
              dateEnd: doc.data().dateEnd.toDate()
            });
          }
        });
        return eventTemplates;
      });
  } catch (err) {
    alert(err.message);
  }
};

export const getEvents = async (callback) => {
  try {
    return await firestore
      .collection('events')
      .get()
      .then((snapshot) => {
        const events = [];
        snapshot.forEach((doc) => {
          if (doc.exists) {
            const registered = [];
            doc.ref
              .collection('registered')
              .get()
              .then((snap) => {
                snap.forEach((d) => {
                  if (d.exists) {
                    registered.push({
                      ...d.data(),
                      createdAt: d.data().createdAt.toDate()
                      // date_of_birth:
                      //   d.data().date_of_birth &&
                      //   d.data().date_of_birth.toDate(),
                    });
                  }
                });
              });
            events.push({
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              dateStart: doc.data().dateStart.toDate(),
              dateEnd: doc.data().dateEnd.toDate(),
              registered
            });
          }
        });
        return events.length > 0 && events;
      });
  } catch (err) {
    alert(err.message);
  }
};

export const getEventRegistrationData = async (url) => {
  try {
    return await firestore
      .collection('events')
      .where('register-url', '==', url)
      .get()
      .then((snapshot) => {
        const event = [];
        snapshot.forEach((doc) => {
          if (doc.exists) {
            event.push({
              ...doc.data(),
              dateStart: doc.data().dateStart.toDate(),
              dateEnd: doc.data().dateEnd.toDate(),
              createdAt: doc.data().createdAt.toDate()
            });
          }
        });

        return event;
      });
  } catch (err) {
    alert(err.message);
  }
};

export const registerToEvent = async (data, id) => {
  try {
    return await firestore
      .collection('events')
      .doc(id)
      .collection('registered')
      .add({
        ...data,
        createdAt: new Date()
      });
  } catch (err) {
    alert(err.message);
  }
};

export const changePublicState = async (publicState, id) => {
  try {
    return await firestore
      .collection('events')
      .doc(id)
      .update({ public: publicState });
  } catch (err) {
    alert(err.message);
  }
};
