import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { getCompany, getEmployee } from '../utils/funcs';
import * as _ from 'lodash';
import { useAuth } from '../contexts/AuthContext';
import Chart from 'react-apexcharts';
import radarBg from '../assets/images/profile-background.svg';
import { ArrowBackIos, PrintOutlined } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import './print.scss';

const Statistics = () => {
  const { cpid, id } = useParams();
  const { currentUser } = useAuth();
  const [employee, setEmployee] = useState(null);
  const [company, setCompany] = useState(null);
  const [perc, setPerc] = useState(null);
  const { t, i18n } = useTranslation();
  const [chart] = useState({
    options: {
      chart: {
        foreColor: 'transparent',
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColors: 'transparent',
            fill: {
              colors: ['transparent']
            },
            connectorColors: 'transparent'
          }
        }
      },
      colors: ['#ffffffaa'],
      xaxis: {
        categories: ['A', 'B', 'C', 'D'],
        max: 100,
        labels: {
          show: false
        }
      },
      yaxis: {
        categories: ['A', 'B', 'C', 'D'],
        max: 100,
        labels: {
          show: false
        }
      },
      tooltip: {
        enabled: false
      }
    }
  });

  useEffect(() => {
    document.title = 'BSC CC - Grundprofil';
    getEmployee(cpid, id).then((data) => {
      setEmployee(data);
    });
    getCompany(cpid).then((data) => setCompany(data));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (employee) {
      const sum =
        employee.stats.A +
        employee.stats.D +
        employee.stats.C +
        employee.stats.D;
      const ad = ((employee.stats.A + employee.stats.D) * 100) / sum;
      const dc = ((employee.stats.D + employee.stats.C) * 100) / sum;
      const cb = ((employee.stats.C + employee.stats.B) * 100) / sum;
      const ba = ((employee.stats.B + employee.stats.A) * 100) / sum;

      setPerc({
        AD: ad,
        DC: dc,
        CB: cb,
        BA: ba
      });
    }
  }, [employee]);

  const sortByKey = (obj) => {
    const sorted = {};
    Object.keys(obj)
      .sort()
      .forEach(function (key) {
        sorted[key] = obj[key];
      });
    return sorted;
  };

  return (
    <div id='statistics' style={{ position: 'relative' }}>
      {
        <div className='result'>
          {((currentUser && company) || company) && (
            <Link
              to={'/questionnaire/' + company.name + '/' + cpid + '/' + id}
              className='back-btn'
            >
              <ArrowBackIos /> Zurück
            </Link>
          )}
          <span className='pre-title'>
            {t('PORTAL FÜR TRAININGSTEILNEHMER', { fallbackLng: 'de' })}
          </span>
          <button
            className='btn-secondary btn-print'
            onClick={() => {
              document.title = `${employee.name} ${employee.surname} - ${
                i18n.language === 'de'
                  ? 'Mein Grundprofil - DE'
                  : 'My basic profile - EN'
              }`;
              window.print();
            }}
            style={{ position: 'absolute', top: -10, right: 0 }}
          >
            <PrintOutlined style={{ stroke: 'none', fontSize: 18 }} />{' '}
            {t('Print')}
          </button>
          <h1>{t('Creativity and competence - My "basic profile"')}</h1>
          {employee && (
            <h2>
              {employee.name} {employee.surname}
            </h2>
          )}
          {employee && (
            <div>
              <div className='result-box'>
                <h3>{t('Ihr Ergebnis', { fallbackLng: 'de' })}</h3>
                <ul>
                  {_.map(sortByKey(employee.stats), (stat, idx) => (
                    <li key={idx} className={idx}>
                      <p>
                        {t('Profilwert', { fallbackLng: 'de' })}{' '}
                        <span>{idx}</span>
                      </p>
                      <h3>{stat | 0}</h3>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='individual-box'>
                <h3>{t('Your individual profile')}</h3>
                <div className='descriptions'>
                  <ul className='desc-list-a'>
                    <li className='big'>A</li>
                    <li>{t('begriffliches', { fallbackLng: 'de' })}</li>
                    <li>{t('logisches Denken', { fallbackLng: 'de' })}</li>
                    <li>{t('analytisch', { fallbackLng: 'de' })}</li>
                    <li>{t('rational', { fallbackLng: 'de' })}</li>
                  </ul>
                  <ul className='desc-list-b'>
                    <li>{t('kontrolliert', { fallbackLng: 'de' })}</li>
                    <li>{t('konservativ', { fallbackLng: 'de' })}</li>
                    <li>{t('organisiert', { fallbackLng: 'de' })}</li>
                    <li>{t('strukturiert', { fallbackLng: 'de' })}</li>
                    <li className='big'>B</li>
                  </ul>
                  <ul className='desc-list-c'>
                    <li>{t('Zwischenmenschlich', { fallbackLng: 'de' })}</li>
                    <li>{t('emotional', { fallbackLng: 'de' })}</li>
                    <li>{t('musikalisch', { fallbackLng: 'de' })}</li>
                    <li>{t('mitteilsam', { fallbackLng: 'de' })}</li>
                    <li className='big'>C</li>
                  </ul>
                  <ul className='desc-list-d'>
                    <li className='big'>D</li>
                    <li>{t('bildhaftes', { fallbackLng: 'de' })}</li>
                    <li>{t('einfallsreich', { fallbackLng: 'de' })}</li>
                    <li>{t('intuitiv', { fallbackLng: 'de' })}</li>
                    <li>{t('konzeptionel', { fallbackLng: 'de' })}</li>
                  </ul>
                </div>
                <div className='radar'>
                  <img className='radar-bg' src={radarBg} alt='Radar' />
                  <Chart
                    className='radar-stats'
                    options={chart.options}
                    series={[
                      {
                        name: '',
                        data: [
                          employee.stats.A | 0,
                          employee.stats.B | 0,
                          employee.stats.C | 0,
                          employee.stats.D | 0
                        ]
                      }
                    ]}
                    type='radar'
                    width={window.innerWidth > 978 ? 500 : 250}
                    height={window.innerWidth > 978 ? 500 : 250}
                  />
                </div>
                <div className='procentages'>
                  <ul>
                    <li className='pr-a'>
                      <p>
                        {t('Anteil', { fallbackLng: 'de' })}{' '}
                        <span className='a'>A</span>/
                        <span className='d'>D</span>
                      </p>
                      <h3>{perc && perc.AD | 0}%</h3>
                    </li>
                    <li className='pr-d'>
                      <p>
                        {t('Anteil', { fallbackLng: 'de' })}{' '}
                        <span className='d'>D</span>/
                        <span className='c'>C</span>
                      </p>
                      <h3>{perc && perc.DC | 0}%</h3>
                    </li>
                    <li className='pr-c'>
                      <p>
                        {t('Anteil', { fallbackLng: 'de' })}{' '}
                        <span className='b'>B</span>/
                        <span className='c'>C</span>
                      </p>
                      <h3>{perc && perc.CB | 0}%</h3>
                    </li>
                    <li className='pr-b'>
                      <p>
                        {t('Anteil', { fallbackLng: 'de' })}{' '}
                        <span className='a'>A</span>/
                        <span className='b'>B</span>
                      </p>
                      <h3>{perc && perc.BA | 0}%</h3>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      }
    </div>
  );
};

export default Statistics;
